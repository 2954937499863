@import '../../sass/variables.scss';

.timepicker {
  .rc-time-picker-input {
    padding: 12px 14px;
    display: block;
    width: 100%;
    outline: 0;
    border-radius: 4px;
    border: 1px solid $grayGray5;
    transition: $tr;
    color: $secondaryColourSecondary7;
    background: $grayGray1;
    height: auto;
    font-size: 14px;
    line-height: 1.71;
    &::placeholder {
      color: $secondaryColourSecondary4;
      opacity: 1;
    }
    &:hover,
    &:focus {
      border-color: $goldenPurplePurple5;
    }
  }
}

.rc-time-picker-panel-narrow {
  max-width: 138px;
}