@import '../../sass/variables.scss';

.escape-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $goldenPurplePurple5;
  transition: $tr;
  z-index: 2;
  cursor: pointer;
  &:hover {
    border-color: $goldenPurplePurple7;
    svg {
      fill: $goldenPurplePurple7;
    }
  }
  svg {
    display: block;
    transition: $tr;
  }
}