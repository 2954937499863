@import '../../sass/variables.scss';

.back-button-top {
  padding: 12px 32px;
  background: $grayGray1;
  &__link {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.14px;
    font-weight: 600;
    text-decoration: none;
    padding: 4px 0;
    svg {
      display: block;
    }
  }
}