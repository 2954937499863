@import "../../sass/variables.scss";

.an-caption {
  text-transform: uppercase;
  font-weight: 600;
  color: $goldenPurplePurple6;
}

@media only screen and (max-width: $mobileWidth) {
  .an-caption {
    font-size: 14px;
    line-height: 2;
    letter-spacing: 0.015em;
  }
}