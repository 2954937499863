@import '../sass/variables.scss';

.onboarding-inputs {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 16px 8px;
  flex-wrap: wrap;
  .form-control {
    width: 100%;
    &:first-child {
      margin-top: 0;
    }
  }
  &_col {
    &_4 {
      max-width: 700px;
    }
  }
  &__custom {
    width: 100%;
  }
}

.onboarding-input {
  width: 100%;
  &__label {
    box-shadow: $cardsDropShadow;
    padding: 20px 42px 20px 16px;
    border: 1px solid $grayGray5;
    background: $grayGray1;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    display: block;
    transition: $tr;
    &_radio {
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);
        width: 16px;
        height: 16px;
        border: 1px solid $grayGray5;
        border-radius: 50%;
        transition: $tr;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &_checkbox {
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid $grayGray5;
        transition: $tr;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  &__elem {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 0;
    &:checked {
      + .onboarding-input {
        &__label {
          background: $layoutBg;
          border-color: $goldenPurplePurple5;
          &:after {
            background-color: $goldenPurplePurple5;
            background-image: url(../static/images/forms/Vector.svg);
            border-color: $goldenPurplePurple5;
          }
        }
      }
    }
  }
  &_col {
    &_4 {
      width: calc(25% - 6px);
    }
    &_2 {
      width: calc(50% - 6px);
    }
  }
}