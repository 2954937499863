@import '../../sass/variables.scss';

.opening-hours {
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.oh-day {
  &__head {
    display: flex;
    justify-content: space-between;
    padding-right: 32px;
    .ui-base-checkbox {
      &__label {
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: 0.06px;
        padding-right: 26px;
        padding-left: 0;
        &:before {
          right: 0;
          left: auto;
        }
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__body-row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__time {
    width: calc(50% - 20px);
  }
  &__add {
    padding-top: 36px;
  }
  &__add-button {
    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
    color: $grayGray5;
    transition: $tr;
    &:hover {
      color: $goldenPurplePurple5;
    }
    svg {
      display: block;
    }
  }
}