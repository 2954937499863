@import '../sass/variables.scss';

.thank-you {
  max-width: 724px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

.thank-you-table {
  max-width: 486px;
  margin-left: auto;
  width: 100%;
  margin-right: auto;
  border-collapse: collapse;
  th {
    padding-bottom: 8px;
  }
  td {
    padding-top: 8px;
    padding-bottom: 8px;
    &.thank-you-table {
      &__plan {
        background: $grayGray1;
        border-radius: 8px;
        padding: 16px;
        box-shadow: $cardsDropShadow;
      }
      &__filler {
        padding-top: 16px;
      }
      &__hr {
        padding-bottom: 16px;
        border-bottom: 1px solid $secondaryColourSecondary7;
      }
    }
    &:nth-child(2) {
      text-align: right;
    }
  }
  tbody {
    tr {
      &:last-child {
        td {
          padding-top: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobileWidth) {
  .thank-you,
  .thank-you-table {
    max-width: 100%;
  }
}