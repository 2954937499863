@import "../../../sass/variables.scss";

.base {
  &.editing {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;

    .checkbox {
      width: 18px;
    }

    .input {
      width: 100%;

      input {
        padding-right: 48px;
      }
    }

    .trash {
      position: absolute;
      right: 48px;
    }

    .trash,
    .plus {
      display: flex;
      cursor: pointer;

      &.disabled {
        opacity: 0.6;
        cursor: default;
      }
      svg {
        color: $grayGray5;
        transition: all ease 0.3s;
      }

      &:not(.disabled) {
        &:hover {
          svg {
            color: $goldenPurplePurple5;
          }
        }
      }
    }
  }

  &.rating {
    display: flex;
    flex-direction: column;
    grid: 8px;
    width: 20%;

    .number {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $grayGray6;
      color: white;
      font-weight: 600;
      border-radius: 999px;
    }

    input {
      padding: 4px;
      font-size: 14px;
      line-height: 1.71;
      border: 1px solid $grayGray6;
      letter-spacing: -0.14px;
      text-align: center;
    }
  }
}
