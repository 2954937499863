@import "../sass//variables.scss";

.top-bar {
  position: fixed;
  top: 0;
  left: 256px;
  right: 0;
  z-index: 3;
  background: $grayGray1;
  height: $topbarHeight;
  display: flex;

  &__line {
    border-bottom: 1px solid $grayGray4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    width: 100%;
  }

  &__profile {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
