@import '../sass/variables.scss';

.unselectable {
  user-select: none;
}

.root {
  cursor: pointer;
  position: fixed;
  background: $grayGray1;
  box-shadow: 1px 0 4px rgba(0, 0, 0, 0.25);
  color: $grayGray8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 290px;
  z-index: 99999;
  padding: 4px 8px;
  font-size: 14px;
  + div {
    z-index: 9999;
    position: relative;
  }
}

.rootLoading {
  cursor: not-allowed;
}

.feedbackIcon {
  height: 12px;
  margin-right: 6px;
}

.submittedIcon {
  height: 14px;
  margin-right: 6px;
}

.modal {
  padding: 16px;
  position: fixed;
  background: $grayGray1;
  box-shadow: 1px 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  right: 290px;
  top: 40px;
  width: 300px;
  z-index: 100;
}

.screenshotOption {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.screenshotOptionText {
  composes: unselectable;
  font-size: 16px;
  margin-left: 10px;
  color: $grayGray8;
}

.screenshotInstructions {
  composes: unselectable;
  color: $grayGray8;
  margin-bottom: 10px;
  font-size: 12px;
  font-style: italic;
}

.btn {
  float: right;
  margin-top: 5px;
  margin-left: 5px;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  outline: none;
  &:hover {
    cursor: pointer;
  }
}

.submit {
  background: $goldenPurplePurple7;
  color: $grayGray1;
  &:disabled {
    background: rgba(187, 107, 217, 0.4);
  }
}

.cancel {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25);
  background-color: $grayGray1;
  color: $grayGray8;
}

.textInput {
  box-sizing: border-box;
  resize: none;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-size: 14px;
  outline: none;
  line-height: 20px;
  color: $grayGray8;
  &::placeholder {
    color: $grayGray6;
    font-size: 12px;
  }
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
}

.overlayScreenshot {
  background: rgba(0, 0, 0, 0.2);
  cursor: crosshair;
}

.activeScreenshotOverlay {
  composes: overlayScreenshot;
  position: fixed;
}

.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 24px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $grayGray4;
  transition: $tr;
  border-radius: 34px;
  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: $grayGray1;
    transition: $tr;
    border-radius: 50%;
  }
}

input {
  &:checked {
    + .slider {
      background-color: #6fcf97;
      &:before {
        transform: translateX(14px);
      }
    }
  }
}

.loader {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  div {
    display: inline-block;
    position: absolute;
    left: 2px;
    width: 4px;
    background: $goldenPurplePurple7;
    animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    &:first-child {
      left: 2px;
      animation-delay: -0.24s;
    }
    &:nth-child(2) {
      left: 8px;
      animation-delay: -0.12s;
    }
    &:nth-child(3) {
      left: 14px;
      animation-delay: 0;
    }
  }
}

@keyframes loader {
  0% {
    top: 2px;
    height: 15px;
  }
  50%, 100% {
    top: 6px;
    height: 8px;
  }
}