@import '../../sass/variables.scss';

.campaign-instances-table {
  td {
    min-height: 64px;
    &:nth-child(3) {
      text-align: center;
    }
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      text-align: right;
      > div {
        > div {
          justify-content: flex-end;
        }
      }
    }
  }
  &__actions {
    cursor: pointer;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
    color: $grayGray5;
    transition: $tr;
    &:hover {
      color: $goldenPurplePurple5;
    }
  }
  table {
    overflow: initial;
  }
  th {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      text-align: right;
      > div {
        > div {
          justify-content: flex-end;
        }
      }
    }
  }
  &_automated-raffles {
    th {
      &:nth-child(2),
      &:nth-child(3) {
        text-align: center;
        > div {
          > div {
            justify-content: space-between;
            > div {
              width: calc(100% - 14px);
              text-align: center;
            }
          }
        }
      }
    }
    td {
      &:nth-child(2),
      &:nth-child(3) {
        text-align: center;
      }
    }
  }
}