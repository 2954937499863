@import '../sass/variables.scss';

.widget-items-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  &__head {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    .form-control {
      width: 50%;
    }
  }
  &__body {
    height: 100%;
    overflow: auto;
    border-radius: 4px;
    border: 1px solid $grayGray5;
    padding: 8px;
    th {
      &:nth-child(3),
      &:nth-child(4) {
        > div {
          > div {
            justify-content: flex-end;
          }
        }
      }
    }
    td {
      &:nth-child(3),
      &:nth-child(4) {
        text-align: right;
      }
    }
    del {
      display: none;
    }
    ins {
      text-decoration: none;
    }
  }
  &__icon {
    color: $grayGray1;
    svg {
      display: block;
      margin: 0 auto;
    }
  }
}