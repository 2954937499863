@import "../../../sass/variables.scss";

.widget-nav-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.widget-nav-btn {
  width: 24px;
  height: 24px;
  padding: 0;
  cursor: pointer;
  outline: 0;
  border: 0;
  background: transparent;
  padding: 2px;
  color: $grayGray5;

  svg {
    transition: all ease $trans3;
  }

  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &:not([disabled]) {
    &:hover {
      color: $goldenPurplePurple5;
    }
  }
}

.variants {
  display: flex;
  align-items: center;
  gap: 16px;
}

.variant {
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 2px;
  border: 1px solid $grayGray5;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled] {
    cursor: default;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: 0;
  }

  &_add {
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: $goldenPurplePurple5;
    background: $grayGray1;
    transition: $tr;

    &:hover {
      border-color: $goldenPurplePurple6;
    }

    svg {
      display: block;
    }
  }

  &_current {
    border-color: $goldenPurplePurple5;
    background: $grayGray5;
    border-width: 2px;
  }
}
