@import "../sass/variables.scss";

.tag-item {
  padding: 8px;
  border: 1px solid $goldenPurplePurple5;
  background: $layoutBg;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  height: fit-content;

  svg {
    width: 16px;
    height: 16px;
    display: block;
    margin: 0;
  }
  &__remove {
    text-decoration: none;
    display: block;
    cursor: pointer;
    min-width: fit-content;
  }

  &_size {
    &_small,
    &_xsmall {
      padding-top: 2px;
      padding-bottom: 2px;
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  &_line-clamp {
    &_1 {
      & > div {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
