@import '../sass/variables.scss';

.complete-progress {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  & div.complete-progress__tooltip {
      z-index: 2;
      background: $grayGray2;
      box-shadow: $cardsDropShadow;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid $secondaryColourSecondary2;
      width: 350px;
      transition: opacity 0.2 ease-in-out, visibility 0.2s ease-in-out !important;
      visibility: visible;
      &.react-tooltip {
        &__show {
          opacity: 1 !important;
          visibility: visible;
        }
        &__arrow {
          display: none;
        }
      }
  }
  &__inner {
    width: 257px;
    max-width: 100%;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }
  &__caption {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  &__body {
    border: 1px solid $secondaryColourSecondary3;
    border-radius: 100px;
    height: 12px;
    background: $grayGray3;
    position: relative;
  }
  &__current {
    border-radius: 100px;
    position: absolute;
    transition: $tr;
    left: -1px;
    background: $goldenPurplePurple2;
    border: 2px solid $goldenPurplePurple5;
    top: -1px;
    bottom: -1px;
  }
  &__icon {
    cursor: pointer;
    svg {
      display: block;
    }
  }
}