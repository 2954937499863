@import "../sass/variables.scss";

.base {
  .inner {
    display: flex;
    gap: 16px;
    align-items: center;

    .icon {
      svg {
        width: 32px;
        height: 32px;
        color: $grayGray5;
        display: block;
      }
    }

    .info {
      flex: 1;

      .channel {
        text-transform: capitalize;
      }
    }
  }
}
