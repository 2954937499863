.section-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  
  &__title {
    flex: 1;
  }
  
  &__filter {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    
    &-without_shrink {
      flex-shrink: 0;
    }
  }
  &_align {
    &_center {
      align-items: center;
    }
  }
}