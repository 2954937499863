@import "../../sass/variables.scss";

.base {
  padding: 4px 8px;
  margin-top: 4px;

  .anchor {
    cursor: pointer;
    svg {
      color: $goldenPurplePurple5;
    }
  }

  .picker {
    position: fixed;
    z-index: 1;
    box-shadow: $cardsDropShadow;
  }
}
