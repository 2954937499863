@import '../../sass/variables.scss';

.file-uploader {
  padding: 23px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 210px;
  border: 1px solid $grayGray5;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  &_customizer {
    padding: 12px 14px 14px;
    border-style: dashed;
    min-height: 0;
    .ui-button {
      &_secondary {
        margin-top: 10px;
      }
    }
    .ui-caption-regular {
      color: $secondaryColourSecondary4;
    }
  }
  &_uploaded {
    padding-bottom: 56px;
  }
  &__preview {
    max-height: 124px;
  }
  &__buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $grayGray4;
    display: flex;
    .ui-button {
      width: 50%;
      border-radius: 0;
      margin-top: 0;
      &:first-child {
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
  &__stock {
    max-height: calc(80vh - 10em);
    overflow: auto;
    img {
      cursor: pointer;
    }
  }
  &_invalid {
    border-color: $dustRedRed5;
  }
}