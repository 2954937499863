@import '../sass/variables.scss';

.complete-setup {
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__row {
    display: flex;
    gap: 32px;
  }
  &__tasks {
    width: 44%;
    position: relative;
  }
  &__state {
    width: calc(56% - 32px);
  }
}

.complete-tasks {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.complete-data {
  display: flex;
  gap: 29px;
  height: 100%;
  &__content {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }
  &__image {
    position: relative;
    margin-top: -27px;
  }
  &__image-wrapper {
    width: calc(50% - 29px);
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__button-wrapper {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }
}