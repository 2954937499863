@import '../../sass/variables.scss';

.base-pill {
  border-radius: 8px;
  border: 1px solid $grayGray5;
  background: $grayGray1;
  box-shadow: $cardsDropShadow;
  padding: 16px;
  color: $secondaryColourSecondary7;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.71;
  transition: $tr;
  display: block;
  width: 100%;
  &:hover {
    border-color: $goldenPurplePurple4;
  }
  &_active {
    background: $layoutBg;
    border-color: $goldenPurplePurple5;
  }
}