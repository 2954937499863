@import "../../sass/variables.scss";

.base {
  cursor: pointer;
  transition: $tr;

  &.selected {
    border-color: $goldenPurplePurple5;
    background: $layoutBg;
  }

  &:hover {
    border-color: $goldenPurplePurple3;
  }
}
