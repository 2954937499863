@import '../sass/variables.scss';

.widget-preview {
  text-decoration: none;
  color: $grayGray9;
  transition: $tr;
  &:not([data-selection="true"]):not(.widget-preview_static) {
    &:active {
      background: $layoutBg;
      border-color: $goldenPurplePurple5;
    }
  }

  &.widget-preview_static {
    background-color: $grayGray4;
  }

  &:not(.widget-preview_static):hover {
    color: $grayGray9;
    border-color: $goldenPurplePurple5;
    .widget-preview {
      &__drag {
        opacity: 1;
      }
    }
  }
  
  &_draggable {
    &:hover {
      background: $layoutBg;
    }
  }

  &[data-selection="true"] {
    cursor: pointer;
  }

  &__campaigns {
    border-top: 1px solid $secondaryColourSecondary4;
  }
  &__being {
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: 0.005em;
  }
  &__campaigns-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__head {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__head-data {
    max-width: calc(100% - 28px);
  }
  &__html {
    height: 189px;
    overflow: hidden;
    position: relative;
    &_draggable {
      border-radius: 2px;
      border: 1px solid $grayGray5;
      height: 108px;
    }
  }
  &__html-inner {
    transform: scale(0.56);
    transform-origin: top left;
    margin-right: -78%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    opacity: 0;
    transition: $tr;
    visibility: hidden;
    &_active {
      opacity: 1;
      visibility: visible;
    }
  }

  &_selection-checkbox {
    label {
      width: 16px;
      height: 16px;
      padding: 0;
    }
  }

  &:nth-child(3n) {
    .dropdown {
      left: auto;
      right: 0;
    }
  }
  .chips {
    vertical-align: top;
  }
  &__dots {
    color: $grayGray5;
    transition: $tr;
    &:hover {
      color: $goldenPurplePurple5;
    }
  }
  &__drag {
    color: $grayGray5;
    cursor: grab;
    transition: $tr;
    opacity: 0;
    &:hover {
      color: $goldenPurplePurple5;
    }
  }
  .widget {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}