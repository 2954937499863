@import '../sass/variables.scss';

.notifications-dropdown {
  border: 1px solid $grayGray5;
  box-shadow: $cardsDropShadow;
  border-radius: 4px;
  position: absolute;
  background: $grayGray1;
  top: 100%;
  margin-top: 28px;
  left: 0;
  width: 284px;
  padding-top: 16px;
  &__head {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
  &__read {
    border: 0;
    padding: 0;
    background: transparent;
    color: $goldenPurplePurple5;
    font-size: 1;
    cursor: pointer;
    transition: $tr;
    outline: 0;
    &:hover {
      color: $goldenPurplePurple7;
    }
  }
  &__nav {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    gap: 16px;
    position: relative;
    border-bottom: 1px solid $grayGray5;
  }
  &__body {
    height: 372px;
  }
}

.notifications-nav-button {
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
  color: $secondaryColourSecondary7;
  transition: $tr;
  padding-bottom: 1px;
  position: relative;
  &:after {
    content: '';
    display: block;
    left: 0;
    right: 0;
    bottom: -1px;
    transition: $tr;
    height: 2px;
    border-radius: 2px;
    position: absolute;
  }
  &_active {
    color: $grayGray9;
    &:after {
      background: $goldenPurplePurple5;
    }
  }
}

.notifications-list {
  list-style: none;
  padding: 0;
  margin: 0;
}