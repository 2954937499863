@import "../../../sass/variables.scss";

.ProductSelector {
  position: relative;
  width: 100%;
  margin: 0 auto;

  .tags-list {
    display: flex;
    margin-top: 8px;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.ProductSelector ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid $grayGray5;
  border-top: none;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 1;
  box-shadow: $cardsDropShadow;
}

.ProductSelector li {
  padding: 8px 14px;
  cursor: pointer;
  height: 48px;
  display: flex;
  align-items: center;
}

.ProductSelector li:hover {
  background-color: #f0f0f0;
}
