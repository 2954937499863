@import "../../../sass/variables.scss";

.base {
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  &_landing {
    flex-direction: column;
    align-items: center;
  }

  .layout {
    height: 90px;
    border: 1px solid $grayGray5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: $cardsDropShadow;
    transition: $tr;

    &_width {
      &_115 {
        width: 115px;
      }
      &_150 {
        width: 150px;
      }
      &_152 {
        width: 152px;
      }
    }

    &:hover {
      border-color: $goldenPurplePurple5;
    }

    &.selected {
      border-color: $goldenPurplePurple5;
      background: $layoutBg;
    }
  }
}
