.payment-history {
  &__head {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
  &__actions {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  td {
    min-height: 72px;
    &:nth-child(5) {
      text-align: center;
    }
    &:nth-child(6) {
      text-align: right;
    }
    svg {
      display: block;
    }
  }
  th {
    &:nth-child(5),
    &:nth-child(6) {
      > div {
        > div {
          justify-content: flex-end;
        }
      }
    }
  }
}