@import '../../sass/variables.scss';

.accordion {
  border-top: 1px solid $grayGray4;
}

.accordion-item {
  border-bottom: 1px solid $grayGray4;
  &__head {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &__icon {
    transition: $tr;
    &_active {
      transform: rotate(180deg);
    }
    svg {
      display: block;
    }
  }
  &__body {
    padding-bottom: 16px;
    margin-top: -8px;
  }
}