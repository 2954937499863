.widget-button {
  min-height: 80px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  &__emoji {
    font-size: 24px;
    line-height: 1.5;
    padding-left: 8px;
    padding-right: 8px;
  }
  &__body {
    width: calc(100% - 40px);
  }
}