@import '../sass/variables.scss';

.dashboard-nav {
  width: 256px;
  background: $goldenPurplePurple7;
  padding: 8px;
  display: flex;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: space-between;
  &__logo {
    display: block;
  }
}

.board-nav {
  border-top: 1px solid $grayGray4;
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 4px;
    flex-direction: column;
  }
}