@import '../../sass/variables.scss';

.activity-table {
  td {
    min-height: 64px;
  }
  &__th {
    padding-right: 28px !important;
    cursor: pointer;
    background: url(../../static/images/common/akar-icons_chevron-vertical.svg) no-repeat right 8px center;
    &:hover,
    &_active {
      background-image: url('../../static/images/common/akar-icons_chevron-vertical copy.svg');
      color: $goldenPurplePurple5;
    }
    &_no-sorting {
      cursor: default;
      background-image: none;
      padding-right: 0 !important;
      &:hover,
      &_active {
        color: #141414 !important;
        background-image: none;
      }
    }
  }
}

.activity-send-email {
  padding-left: 12px;
  // border-left: 1px solid $grayGray5;
}

.without-label {
  label {
    width: 16px;
    height: 16px;
    padding: 0;
  }
}

.icon-button {
  cursor: pointer;
  color: $grayGray5;
  
  &:not(.disabled):hover {
    color: $goldenPurplePurple7;
  }
}