@import "../../sass/variables.scss";

.base {
  [class~="dropdown"] {
    left: 0;
  }

  .color-box {
    width: 32px;
    height: 32px;
    border: 1px solid $grayGray5;
    display: block;
    border-radius: 2px;
    bottom: 10px;
    left: 14px;
    z-index: 1;
    position: absolute;
  }

  input {
    padding-left: 54px;
    cursor: pointer;
    text-transform: uppercase;
  }

  [class~="sketch-picker"] {
    width: fit-content !important;
  }
}
