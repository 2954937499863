@import "variables.scss";

.ui-caption-regular {
  font-size: 14px;
  line-height: 1.71;
}

.ui-caption-medium {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.004em;
  font-weight: 500;
}

.ui-body-regular {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.01em;
  color: $secondaryColourSecondary7;
}

.ui-body-bold {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.01em;
  font-weight: 700;
}

.ui-body-medium {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.ui-extra-small-regular {
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 0.005em;
}

.ui-extra-small-medium {
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 0.005em;
  font-weight: 500;
}

.ui-small-regular {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.005em;
}

.ui-small-medium {
  font-size: 12px;
  line-height: 1.67;
  font-weight: 500;
  letter-spacing: 0.005em;
}

.ui-other-headings-h6-bold {
  margin: 0;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.015em;
  font-weight: 600;
}

.ui-paragraph-medium {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  font-weight: 500;
}
.email-templates-h1-semibold {
  font-size: 34px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.5%;
}

.email-templates-subtitle-3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.5%;
}

.email-templates-paragraph-1-regular {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.01em;
}

.email-templates-paragraph-1-medium {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.email-templates-paragraph-2-regular {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.01em;
}

.email-templates-paragraph-2-medium {
  font-size: 14px;
  line-height: 1.71;
  font-weight: 500;
  letter-spacing: -0.14px;
}

.email-templates-overline {
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  color: $goldenPurplePurple5;
}

.email-templates-subtitle-2-medium {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 500;
  margin: 0;
}

.email-templates-subtitle-2-bold {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.01em;
  font-weight: 700;
}

.email-templates-subtitle-3-semibold {
  font-weight: 600;
  margin: 0;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.005em;
}

.email-templates-caption-regular {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.005em;
}

.email-templates-caption-medium {
  font-size: 12px;
  line-height: 1.67;
  font-weight: 500;
  letter-spacing: 0.005em;
}

.email-templates-button-xs-medium {
  font-size: 10px;
  line-height: 1.6;
  font-weight: 500;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}

.email-templates-button-small-medium {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.005em;
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
  .button-group {
    justify-content: flex-end;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.an-col {
  &_3 {
    width: calc(100% / 3);
    flex-grow: 0 !important;

    &_12 {
      flex-grow: 0 !important;
      width: calc(100% / 3 - 12px);
    }

    &_16 {
      flex-grow: 0 !important;
      width: calc(100% / 3 - 16px);
    }
  }

  &_7 {
    width: calc(100% - 100% / 3);
    flex-grow: 0 !important;

    &_16 {
      flex-grow: 0 !important;
      width: calc(100% - 100% / 3 - 16px);
    }

    &_12 {
      flex-grow: 0 !important;
      width: calc(100% - 100% / 3 - 12px);
    }
  }

  &_25 {
    width: calc(25% - 4px);
    flex-grow: initial !important;
  }
  &_28 {
    width: calc(28% - 4px);
    flex-grow: initial !important;
  }
  &_32 {
    width: calc(32% - 4px);
    flex-grow: initial !important;
  }
  &_33 {
    width: calc(33% - 12px);
    flex-grow: initial !important;
    &_16 {
      width: calc(33% - 16px);
    }
  }
  &_39 {
    width: calc(39% - 12px);
    flex-grow: initial !important;
  }
  &_47 {
    width: calc(47% - 12px);
    flex-grow: initial !important;
  }
  &_50 {
    width: calc(50% - 12px);
    flex-grow: initial !important;
  }
  &_53 {
    width: calc(53% - 12px);
    flex-grow: initial !important;
  }
  &_61 {
    width: calc(61% - 12px);
    flex-grow: initial !important;
  }
  &_67 {
    width: calc(67% - 12px);
    flex-grow: initial !important;
  }
  &_68 {
    width: calc(68% - 4px);
    flex-grow: initial !important;
  }
}

.card {
  padding: 24px 32px;
  background: $grayGray1;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  &_8 {
    padding: 8px;
    &_18 {
      padding: 18px 8px 18px 16px;
    }
  }
  &_16 {
    padding: 16px;
    &_8 {
      &_16 {
        &_16 {
          padding: 16px 8px 16px 16px;
        }
      }
    }
  }
  &_24 {
    padding: 24px;
  }
  &_19 {
    &_8 {
      padding: 19px 8px;
    }
  }
  &_br {
    &_24 {
      border-radius: 24px;
    }
  }
  &_shadow {
    box-shadow: $cardsDropShadow;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    align-items: center;
  }
  &_flex {
    display: flex;
    flex-direction: column;
  }
  &__body {
    height: 100%;
  }
  &_border {
    border: 1px solid $grayGray5;
  }
  &_overflow {
    &_visible {
      overflow: initial;
    }
  }
  &_mh {
    &_230 {
      min-height: 230px;
    }
  }
  &_h {
    &_100 {
      height: 100%;
    }
    &_survey {
      height: calc(100vh - 188px);
    }
  }
  &_align {
    &_center {
      justify-content: center;
    }
  }
  &__overflow {
    max-height: 100%;
    overflow: auto;
  }
  &_mw {
    &_100 {
      max-width: 100%;
    }
  }
}

.blured {
  position: relative;
  &:after {
    content: "";
    display: block;
    border-radius: 24px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    border: 1px solid $grayGray3;
  }
  &_br_8 {
    &:after {
      border-radius: 8px;
    }
  }
}

.an-overlay {
  background: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.an-modal {
  width: 700px;
  max-width: 90%;
  z-index: 100;
  top: 50%;
  left: 50%;
  right: auto;
  position: absolute;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: $grayGray1;
  padding: 24px;
  border-radius: 16px;
  max-height: 90vh;

  &__max-w-600 {
    width: 600px;
  }

  &__rounded-8 {
    border-radius: 8px;
  }
  
  &__maxh-unset {
    max-height: unset;
  }
  &__body {
    position: relative;
    min-height: 64px;
    &_mh {
      &_0 {
        min-height: 0;
      }
    }
  }
  &__head {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__close {
    border: 0;
    outline: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
    svg {
      display: block;
    }
  }
  &__title {
    &_fill {
      width: calc(100% - 32px);
    }
  }
  &_w {
    &_400 {
      width: 400px;
    }
    &_490 {
      width: 490px;
    }
  }
}

body {
  .form-select {
    padding: 12px 16px;
    background: $grayGray1;
    border: 1px solid $grayGray5;
    border-radius: 4px;
    gap: 8px;
    outline: 0;
    transition: $tr;
    input {
      margin: 0;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: -0.01em;
      color: $secondaryColourSecondary4;
    }
    .react-dropdown-select-content {
      margin: 0;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: -0.01em;
      color: $secondaryColourSecondary7;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
    .react-dropdown-select-dropdown-handle {
      margin: 0;
      width: 16px;
      height: 16px;
      background: url(../static/images/forms/Icon.svg) no-repeat center center;
      transform: none;
      transition: $tr;
      svg {
        display: none;
      }
    }
    &:hover {
      border-color: $goldenPurplePurple5;
    }
    &[aria-expanded="true"] {
      border-color: $goldenPurplePurple5;
      box-shadow: none;
      .react-dropdown-select-dropdown-handle {
        transform: rotate(180deg);
        background-image: url("../static/images/forms/Icon copy.svg");
      }
    }
    .react-dropdown-select-dropdown {
      margin-top: -4px;
      box-shadow: $cardsDropShadow;
      border: 1px solid $grayGray5;
      border-radius: 4px;
    }
    .react-dropdown-select-item {
      padding: 12px 14px;
      font-size: 14px;
      line-height: 1.71;
      color: $secondaryColourSecondary7;
      transition: $tr;
      &:hover {
        background: $grayGray3;
      }
      &.react-dropdown-select-item-selected {
        background: $grayGray4;
      }
      &.react-dropdown-select-item-disabled {
        color: $grayGray5;
        cursor: default;
        background: transparent;
        &:hover {
          color: $grayGray5;
          background: transparent;
        }
      }
      ins {
        display: none;
      }
    }
  }
}

.button-group {
  display: flex;
  gap: 8px;
  position: relative;
  &_gap {
    &_16 {
      gap: 16px;
    }
  }
  &_center {
    justify-content: center;
  }
}

.an-layout {
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &_gap {
    &_32 {
      gap: 32px;
    }
    &_16 {
      gap: 16px;
    }
    &_8 {
      gap: 8px;
    }
  }
}

.an-row {
  display: flex;
  gap: 24px;
  > div {
    flex-grow: 1;
    flex-shrink: 0;
  }
  &_fill {
    > div {
      flex: 1;
    }
  }
  &_flex-wrap {
    flex-wrap: wrap;
    .an-col {
      &_33 {
        width: calc(33.3333333% - 16px);
      }
      &_50 {
        width: calc(50% - 16px);
      }
    }
  }
  &_gap {
    &_8 {
      gap: 8px;
    }
    &_16 {
      gap: 16px;
    }
  }
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.gap-8 {
  gap: 8px;
}

.maxw {
  margin-left: auto;
  margin-right: auto;
  &_431 {
    width: 431px;
    max-width: 100%;
  }
  &_601 {
    max-width: 601px;
  }
  &_825 {
    width: 825px;
    max-width: 100%;
  }
}

.underline {
  text-decoration: underline !important;
}

.bt-1 {
  border-top: 1px solid $grayGray5;
}

.d-block {
  display: block;
  width: 100%;
}

.d-flex {
  display: flex;
  &_column {
    flex-direction: column;
  }
  &_gap {
    &_8 {
      gap: 8px;
    }
    &_12 {
      gap: 12px;
    }
  }
  &_jc {
    &_space {
      justify-content: space-between;
    }
    &_center {
      justify-content: center;
    }
  }
}

.action-btn {
  border: 0;
  background: transparent;
  outline: 0;
  padding: 0;
  cursor: pointer;
  padding: 5px;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: $grayGray1;
  width: 287px;
  z-index: 2;
  box-shadow: $cardsDropShadow;
  &_widget {
    width: 228px;
    border: 1px solid $grayGray5;
    bottom: 100%;
    top: auto;
    margin-bottom: 4px;
    border-radius: 4px;
  }
  &_filter {
    margin-top: 10px;
  }
  &_campaign {
    width: 287px;
    border: 1px solid $grayGray5;
    border-radius: 4px;
  }
  &_position {
    &_bottom {
      top: 100%;
      margin-top: 4px;
      margin-bottom: auto;
      bottom: auto;
      left: 0;
      right: auto;
    }
  }
  &_w {
    &_340 {
      width: 340px;
    }
  }
  &__item {
    padding: 12px 14px;
    display: flex;
    width: 100%;
    text-align: left;
    border: 0;
    outline: 0;
    background: transparent;
    color: $secondaryColourSecondary7;
    cursor: pointer;
    transition: $tr;
    align-items: center;
    gap: 8px;
    &:hover {
      background: $grayGray3;
      color: $secondaryColourSecondary7;
    }
    &[disabled] {
      color: $grayGray5;
      cursor: default;
      &:hover {
        color: $grayGray5;
        background: transparent;
      }
    }
    &_selected {
      background: $grayGray4;
    }
  }
  &__item-icon {
    padding: 2px;
    display: block;
    color: $grayGray5;
    svg {
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}

.position-relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.text-capitalize {
  &:first-letter {
    text-transform: uppercase;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-fit-content {
  height: fit-content;
}

.min-h-100 {
  min-height: 100%;
}

.max-h-100 {
  max-height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.over-flow-hidden {
  overflow: hidden;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 20px;
    background-color: #cdcccc;
  }
}

.email-preview {
  box-sizing: initial;
  * {
    box-sizing: initial;
  }
  .widget {
    padding-left: 20px;
    padding-right: 20px;
  }
  > table {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.App {
  .an-tooltip {
    z-index: 2;
    background: $grayGray2;
    box-shadow: $cardsDropShadow;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $secondaryColourSecondary2;
    width: 350px;
    transition: opacity 0.2 ease-in-out, visibility 0.2s ease-in-out !important;
    visibility: visible;
    &.react-tooltip {
      &__show {
        opacity: 1 !important;
        visibility: visible;
      }
      &__arrow {
        display: none;
      }
    }
    &_w {
      &_289 {
        width: 289px;
      }
      &_300 {
        width: 300px;
      }
    }
    &_white {
      background: $grayGray1;
    }
  }
}

.max-overflow-100 {
  max-height: 100%;
  overflow: auto;
}

.cp-steps-wrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.widget-actions {
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
}

.nowrap-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-break-spaces {
  white-space: break-spaces;
}

.email-container {
  width: 100% !important;
}

.table-align-left {
  text-align: left !important;
  > div {
    > div {
      align-items: flex-start;
    }
  }
}

.table-align-right {
  text-align: right !important;
  > div {
    > div {
      align-items: flex-end;
    }
  }
}

.table-align-center {
  text-align: center !important;
  > div {
    > div {
      align-items: center;
    }
  }
}

.svg-icon-wrapper {
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  svg {
    display: block;
    margin: 0;
  }
}

.screen-reader-text {
  display: none;
}

@media only screen and (max-width: $mobileWidth) {
  .mobile-hidden {
    display: none;
  }
  .text-center-mobile {
    text-align: center;
  }
  .text-left-mobile {
    text-align: left;
  }
  .ui-caption-regular-mobile {
    font-size: 14px;
    line-height: 1.71;
  }
  .ui-caption-medium-mobile {
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0.004em;
    font-weight: 500;
  }
  .ui-other-headings-h5-bold-mobile {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
  }
  .ui-other-headings-h6-bold-mobile {
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.015em;
    font-weight: 600;
  }
  .ui-body-bold-mobile {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -0.01em;
    font-weight: 700;
  }
  .ui-small-medium-mobile {
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.005em;
  }
}

font-thin {
  font-weight: 100;
}

font-extralight {
  font-weight: 200;
}

font-light {
  font-weight: 300;
}

font-normal {
  font-weight: 400;
}

font-medium {
  font-weight: 500;
}

font-semibold {
  font-weight: 600;
}

font-bold {
  font-weight: 700;
}

font-extrabold {
  font-weight: 800;
}

font-black {
  font-weight: 900;
}

.view-btn {
  outline: 0;
  padding: 0;
  color: $grayGray5;
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: $tr;
  &:hover {
    color: $goldenPurplePurple5;
  }
}

.oq-list {
  &__item {
    padding-top: 24px;
    padding-bottom: 24px;
    border-top: 1px solid $grayGray5;
  }
}