.top-sellers-table {
  th,
  td {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      text-align: right;
      > div {
        > div {
          justify-content: flex-end;
        }
      }
    }
  }
  tbody {
    max-height: 252px;
    overflow: auto;
  }
  thead {
    position: sticky;
    top: 0;
  }
  table {
    max-height: 292px;
    overflow: auto;
  }
}