.onboarding-questionnaire {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  position: relative;
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__sync {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}