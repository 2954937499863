@import '../sass/variables.scss';

.revenue-legends {
  display: flex;
  gap: 16px;
  align-items: center;
}

.revenue-legend {
  padding-left: 12px;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background: $goldenPurplePurple5;
  }
  &_this {
    &:before {
      background: $primaryColourBranding5;
    }
  }
}