@import '../../sass/variables.scss';

.store-settings {
  &__section {
    padding-bottom: 24px;
    border-bottom: 1px solid $grayGray5;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}