@import "../../sass/variables.scss";

.base {
  border-radius: 8px;
  border: 1px solid $grayGray5;
  box-shadow: $cardsDropShadow;
  transition: $tr;
  &:hover {
    & > .header .title svg.info {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: default;
  }

  &.preview {
    overflow: hidden;

    & > .header {
      .chevron-down {
        svg {
          transform: rotate(270deg);
        }
      }
    }
    &.open {
      & > .header {
        .chevron-down {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &:not(.open) {
    &:not(.disabled) {
      &:hover {
        border-color: $goldenPurplePurple5;
      }
    }
  }

  &.small {
    & > .header {
      padding: 16px;

      .icon {
        svg {
          width: auto;
          height: 20px;
        }
      }

      .chevron-down {
        svg {
          width: 16px;
        }
      }
    }
  }

  &.open {
    &.collapsible {
      & > .header {
        border-radius: 8px 8px 0 0;
      }
    }

    & > .header {
      background: #f0f2f5;
      border-radius: 8px;

      .title {
        color: $goldenPurplePurple5;
      }

      .icon {
        svg {
          color: $goldenPurplePurple5;
        }
      }

      .description {
        color: $secondaryColourSecondary7;
      }

      .chevron-down {
        svg {
          color: $goldenPurplePurple5;
          rotate: 180deg;
        }
      }
    }
  }

  & > .header {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    gap: 8px;
    border-radius: 8px;

    &.valid {
      background: $layoutBg;
    }

    .icon {
      display: flex;

      svg {
        color: $grayGray5;
        width: auto;
        height: 41px;
        transition: all ease $trans3;
      }
    }

    .title {
      color: $secondaryColourSecondary7;
      transition: all ease $trans3;
      display: flex;
      align-items: center;
      gap: 8px;

      svg:not(.info) {
        display: block;
      }

      svg.info {
        opacity: 0;
        transition: opacity .2s;
      }
    }

    .description {
      color: $secondaryColourSecondary4;
      transition: all ease $trans3;
    }

    .content {
      flex: 1;
    }

    .chevron-down {
      svg {
        display: block;
        width: 24px;
        color: $grayGray5;
        transition: all ease $trans3;
      }
    }
  }

  .body {
    padding: 16px 8px;
    border-top: 1px solid $grayGray5;
    border-radius: 0 0 8px 8px;
  }

  textarea {
    height: 100px;
  }
}
