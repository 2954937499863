@import "../../sass/variables.scss";

.base {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .instance {
    width: 48px;
    height: 48px;
    border: 1px solid $goldenPurplePurple5;
    background: transparent;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: $goldenPurplePurple5;
    }

    &.active {
      background: $goldenPurplePurple5;

      svg {
        color: white;
      }
    }
  }
  
  .mid-buttons {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .nav {
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
    
    svg {
      display: block;
    }
  }
}
