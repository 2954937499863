@import "../../../sass/variables.scss";

.base {
  display: flex;
  gap: 8px;
  align-items: flex-start;

  &:hover {
    .box {
      border-color: $goldenPurplePurple5;
    }
    .actions {
      opacity: 1;
      visibility: visible;
    }
  }

  &.editing {
    .box {
      cursor: default;
      border-color: $goldenPurplePurple5;
      background: $layoutBg;
    }
    .actions {
      opacity: 1;
      visibility: visible;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    opacity: 0;
    visibility: hidden;
    transition: $tr;

    .action {
      display: flex;
      cursor: pointer;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      border-radius: 999px;

      svg {
        color: $grayGray5;
        transition: all ease 0.3s;
      }

      &.active {
        background: $goldenPurplePurple2;

        svg {
          color: $goldenPurplePurple5;
        }
      }

      &.disabled {
        opacity: 0.6;
        cursor: default;
      }

      &:not(.disabled) {
        &:hover {
          svg {
            color: $goldenPurplePurple5;
          }
        }
      }
    }

    .move-up-down {
      display: flex;
      gap: 16px;
      flex-direction: column;
    }
  }

  .title {
    min-height: 112px;
  }

  .box {
    border: 1px solid $grayGray5;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 28px;
    box-shadow: $cardsDropShadow;
    position: relative;
    width: 100%;
    transition: all ease 0.3s;
    cursor: pointer;
    background: white;

    // .overlay {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   z-index: 2;
    // }

    .option-container {
      gap: 16px;
      display: flex;
      flex-direction: column;
      width: 100%;

      &.rating {
        flex-direction: row;
      }
    }

    .top {
      &_actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
