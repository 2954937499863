.sync-in-progress {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  &__preloader {
    position: relative;
    width: 64px;
    height: 64px;
    margin-left: auto;
    margin-right: auto;
    .preloader {
      position: absolute;
      transform: none;
      left: 0;
      top: 0;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  &__button {
    min-width: 192px;
  }
  &__social {
    width: 40px;
    height: 40px;
    display: block;
    text-decoration: none;
  }
  &__social-icon {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
  }
}