@import '../sass/variables.scss';

.products-filter {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  &__inner {
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 96px);
  }
  &__summary {
    height: 96px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .ui-button {
    &_secondary {
      &:hover {
        background: $goldenPurplePurple5;
        color: $grayGray1;
      }
    }
  }
  &__selection {
    overflow: auto;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}