.legend {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  &__label {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__color {
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }
  &__value {
    white-space: nowrap;
  }
}