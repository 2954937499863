.product-preview {
  display: flex;
  gap: 8px;
  align-items: center;
  &__image {
    width: 40px;
    height: 40px;
    display: block;
    margin: 0;
    object-fit: contain;
  }
  &__name {
    width: calc(100% - 48px);
    text-overflow: ellipsis;
    overflow: hidden;
  }
}