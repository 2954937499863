@import '../sass/variables.scss';

.remove-button {
  border: 0;
  background: transparent;
  outline: 0;
  padding: 0;
  cursor: pointer;
  padding: 5px;
  svg {
    display: block;
  }
}

.blue-btn {
  color: $geekBlueGeekBlue6;
  background: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  text-decoration: underline !important;
  &:hover {
    text-decoration: none !important;
  }
}