@import "../../../sass/variables.scss";

.base {
  border: 1px solid $grayGray5;
  border-radius: 8px;
  padding: 16px 32px;
  position: relative;
  display: flex;
  flex-direction: column;

  .inner {
    border: 1px solid $grayGray5;
    border-radius: 4px;
    height: 100%;
    overflow: auto;

    &_widgets {
      height: calc(100% - 84px);
    }
  }

  .customizer-preview {
    background: #f6f6f6;
    width: 100%;
    height: 100%;
    border: 1px solid $grayGray5;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    transition: width ease 0.3s;
    
    &.no-border {
      border: none !important;
    }

    &.mobile {
      width: 360px;
    }
  }
}