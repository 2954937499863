@import "../../sass/variables.scss";

.filter {
	position: relative;
	
	.content {
		position: fixed;
		border-radius: 4px;
		background-color: $grayGray1;
		width: 287px;
		right: 0;
		display: flex;
		flex-direction: column;
    z-index: 2;
    border: 1px solid $grayGray5;
    margin-top: 4px;
		
		&_header {
			display: flex;
			justify-content: space-between;
			padding: 8px 14px;
      border-bottom: 1px solid $grayGray5;
			
			.reset_button {
        color: $grayGray6;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
			}
		}
    
    &_body {
      border-bottom: 1px solid $grayGray5;
      display: flex;
      flex-direction: column;
      
      & > div {
        padding: 8px 14px;
      }
    }
    
    &_footer {
      padding: 8px 14px;
      text-align: right;
    }
	}
}

.ui-base-checkbox {
  &.fluid {
    & .ui-base-checkbox__label {
      width: 100%;
    }
  }
  
  &.reverse {
    & .ui-base-checkbox__label {
      padding-left: 0px;
      padding-right: 34px;
    
      &::before {
        right: 0;
        left: unset;
      }
    }
  }
}