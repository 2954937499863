@import '../../sass/variables.scss';

.cf-accordion {
  min-height: 448px;
  &__title {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 24px;
    cursor: pointer;
    gap: 32px;
    &:hover {
      .cf-accordion {
        &__icon {
          color: $magentaMagenta5;
        }
      }
    }
  }
  &__item {
    border-bottom: 1px solid $grayGray5;
    &:last-child {
      border-bottom: 0;
    }
    &_active {
      .cf-accordion {
        &__icon {
          color: $magentaMagenta5;
          transform: rotate(180deg);
        }
        &__body {
          display: block;
        }
      }
    }
  }
  &__body {
    display: none;
  }
  &__icon {
    color: $goldenPurplePurple5;
    transition: $tr;
    svg {
      display: block;
      margin: 0;
    }
  }
}

.cf-answers-table {
  width: 100%;
  border-collapse: collapse;
  tbody {
    display: block;
    max-height: 252px;
    overflow: auto;
  }
  td {
    padding: 24px 8px;
    border-bottom: 1px solid $grayGray4;
    text-align: right;
    width: 23%;
    &:first-child {
      text-align: left;
      width: 77%;
    }
    &.text-left {
      text-align: left;
    }
  }
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th {
    border-bottom: 1px solid $secondaryColourSecondary4;
    padding: 10px 8px;
    text-align: right;
    &:first-child {
      text-align: left;
    }
    &.text-left {
      text-align: left;
    }
  }
  &__product-image {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 4px;
    display: block;
    margin: 0;
  }
  &__product {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__product-link,
  &__product-meta {
    color: $secondaryColourSecondary7;
  }
  &_order {
    margin-top: -16px;
    td {
      width: 28%;
      padding-top: 4px;
      padding-bottom: 4px;
      &:first-child {
        width: 44%;
        padding-top: 12px;
      }
    }
  }
  &_oq {
    th,
    td {
      width: 23%;
      &:first-child {
        width: 77%;
      }
    }
  }
}

.cf-modal-bottom {
  padding-top: 24px;
  border-top: 1px solid $grayGray5;
}