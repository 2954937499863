@import '../sass/variables.scss';

.customer-help-chat {
  box-shadow: $dropShadow;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $goldenPurplePurple5;
  position: fixed;
  bottom: 32px;
  right: 32px;
  border: 0;
  cursor: pointer;
  z-index: 2;
}