@import "../sass//variables.scss";

.main {
  display: flex;
  height: 100dvh;
}

.main-body {
  padding-left: 256px;
  margin-top: $topbarHeight;

  .inner {
    max-height: calc(100dvh - $topbarHeight);
    position: relative;
    width: calc(100vw - 256px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
