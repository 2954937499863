.campaign-name {
  display: flex;
  gap: 8px;
  align-items: center;
}

.campaigns-table {
  th,
  td {
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      text-align: right;
      > div {
        > div {
          justify-content: flex-end;
        }
      }
    }
    
  }
  th {
    &:nth-child(2) {
      text-align: right;
      > div {
        > div {
          justify-content: flex-end;
        }
      }
    }
  }
  td {
    &:nth-child(2) {
      text-align: center;
    }
  }
}