@import "../sass/variables.scss";

.login-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 16px;
  padding-bottom: 16px;
  &__inner {
    display: flex;
    width: 100%;
    align-items: center;
    height: 100%;
    min-height: calc(100dvh - 120px);
  }
  &__image {
    position: absolute;
    right: 0;
    top: -59px;
    bottom: -167px;
    max-width: 45.35%;
    height: calc(100% + 226px);
  }
  &__content {
    padding-left: 64px;
    padding-right: 64px;
    width: 46.23%;
    &_sign-up {
      width: 53.92%;
    }
  }
}

.login-form {
  max-width: 486px;
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  &_sign-up {
    max-width: 100%;
  }
}

.login-mobile-image {
  display: none;
  background: url('../static/images/login/Rectangle 133.svg') no-repeat top center;
  background-size: cover;
  height: 345px;
  position: relative;
  &__icon {
    max-width: 207px;
    height: auto;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: $mobileWidth) {
  .login-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    gap: 40px;
    min-height: calc(100vh - 72px);
    padding-bottom: 0;
    .container {
      width: 100%;
    }
    &__inner {
      display: block;
      min-height: initial;
    }
    &__image {
      display: none;
    }
    &__content {
      width: auto;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .login-form {
    max-width: 100%;
  }
  .login-mobile-image {
    display: block;
  }
}