@import '../../sass/variables.scss';

.notification {
  position: fixed;
  top: 32px;
  right: 32px;
  border: 1px solid $polarGreenGreen6;
  background: $sunriseYellowYellow1;
  padding: 24px;
  display: flex;
  gap: 16px;
  border-radius: 8px;
  z-index: 3;
  width: 600px;
  align-items: center;
  &__icon {
    padding: 2px;
    svg {
      display: block;
    }
  }
}