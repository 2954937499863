@import '../../sass/variables.scss';

.stat {
  position: relative;
  & .hoverable {
    border: 1px solid $grayGray5;
    cursor: pointer;
    transition: all .2s;

    & .info {
      margin-left: 5px;
      opacity: 0;
      transition: all .2s;
    }

    &:hover {
      border-color: $goldenPurplePurple5;

      & .info {
        opacity: 1;
      }
    }
  }
  &__chips {
    position: absolute;
    right: 8px;
    top: 18px;
  }
  & div.stat__tooltip {
    z-index: 2;
    background: $grayGray2;
    box-shadow: $cardsDropShadow;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $secondaryColourSecondary2;
    width: 350px;
    transition: opacity 0.2 ease-in-out, visibility 0.2s ease-in-out !important;
    visibility: visible;
    // position: absolute;
    // left: 65% !important;
    // top: 80% !important;
    &.react-tooltip {
      &__show {
        opacity: 1 !important;
        visibility: visible;
      }
      &__arrow {
        display: none;
      }
    }
  }
}