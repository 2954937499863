@import '../sass/variables.scss';

.campaigns-counter {
  display: flex;
  gap: 8px;
  white-space: nowrap;
  align-items: center;
  &__count {
    color: $polarGreenGreen6;
    border: 1px solid $polarGreenGreen6;
    background: $polarGreenGreen2;
    padding: 4px 16px;
    border-radius: 8px;
  }
}