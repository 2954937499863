@import '../../sass/variables.scss';

.pagination {
  display: flex;
  gap: 2px;
  button {
    padding: 0;
    background: transparent;
    outline: 0;
    border: 0;
    width: 38px;
    font-size: 14px;
    line-height: 1.71;
    cursor: pointer;
    border-radius: 8px;
    transition: $tr;
    letter-spacing: -0.14px;
    padding: 4px;
    font-weight: 600;
    color: $secondaryColourSecondary7;
    &:first-child {
      margin-right: 14px;
      width: 24px;
      height: 24px;
    }
    &:last-child {
      margin-left: 14px;
      width: 24px;
      height: 24px;
    }
  }
  span {
    width: 38px;
    text-align: center;
    font-size: 14px;
    line-height: 1.71;
    font-weight: 600;
    letter-spacing: -0.14px;
    padding: 4px;
    color: $secondaryColourSecondary7;
  }
  .active {
    color: $goldenPurplePurple5;
    border: 1px solid $goldenPurplePurple5;
  }
}