@import '../../sass/variables.scss';

.selected-main-img {
  object-fit: cover;
  text-align: center;
  max-width: 122px;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
  }
}

.images-container {
  height: 108px;
  background: $layoutBg;
  border-radius: 4px;
  padding: 16px 0px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__main {
    width: 304px;
    margin-bottom: 8px;
  }

  &__else {
    display: flex;
    gap: 16px;
    margin-bottom: 8px;
  }

  .slick-slide {
    padding-right: 13px;
    height: 48px;
  }

  .image-container {
    position: relative;
    width: 48px;
    height: 48px;

    .selected-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-12px, -12px);
      color: #fff;
    }

    .selected-image {
      border: 1px solid $goldenPurplePurple5;
    }

    img:not(.selected-image) {
      opacity: 0.4;
    }

    img {
      cursor: pointer;
      object-fit: cover;
    }
  }
}