@import "../sass/variables.scss";

.an-accordion {
  border-bottom: 1px solid $secondaryColourSecondary3;
  &__title {
    padding: 24px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  &__item {
    border-top: 1px solid $secondaryColourSecondary3;
    &_active {
      .an-accordion {
        &__icon {
          transform: rotate(180deg);
          path {
            fill: $magentaMagenta4;
          }
        }
        &__body {
          display: block;
        }
      }
    }
  }
  &__body {
    padding-bottom: 24px;
  }
  &__icon {
    width: 34px;
    height: 34px;
    display: block;
    margin: 0;
    transition: $tr;
  }
  &__body {
    display: none;
  }
}