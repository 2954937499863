@import "../../../sass/variables.scss";

.title {
	display: flex;
	align-items: center;
	gap: 4px;
  margin-bottom: 4px;
}

.input {
  display: flex;
  overflow: hidden;
	width: 100%;
	outline: 0;
	border-radius: 4px;
	border: 1px solid $grayGray5;
	transition: $tr;
	color: $secondaryColourSecondary7;
	background: $grayGray1;
  &::placeholder {
    color: $secondaryColourSecondary4;
    opacity: 1;
  }
  
  &:hover,
  &:focus {
    border-color: $goldenPurplePurple5;
  }
  & input {
    height: 100%;
    width: 100%;
    /* display: block; */
    padding: 12px 14px;
    outline: 0;
    font-size: 14px;
    line-height: 1.71;
    border: none;
    
    &[type="number"] {
      -moz-appearance: textfield;
  
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  
  .chevrons {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #d9d9d9;
    flex: 1;
    justify-content: center;
    height: 45px;

    & svg:hover {
      stroke: $goldenPurplePurple5;
    }
    
    & > div {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      flex: 1;
      padding: 0px 8px;
      &:first-child {
        align-items: flex-end;
      }
    }
  }
}

.wrapper_input {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.days {
  display: flex;
  gap: 25px;
  padding: 12px 0px;
}

.limits {
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 10px;
  
  & .mins {
    width: 100px;
  }
}