@import "../sass/variables.scss";

.header {
  background: $grayGray1;
  padding-top: 21px;
  padding-bottom: 21px;
  position: sticky;
  z-index: 1;
  box-shadow: $cardsDropShadow;
  top: 0;
  z-index: 2;
  &__logo {
    max-width: 209px;
    display: block;
    text-decoration: none;
    img {
      display: block;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_onboarding {
    box-shadow: none;
    background: transparent;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    .container {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

@media only screen and (max-width: $mobileWidth) {
  .header {
    padding-top: 24px;
    padding-bottom: 24px;
    &__logo {
      max-width: 109px;
    }
  }
}