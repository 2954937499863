.campaign-performance-link {
  display: flex;
  align-items: center;
  gap: 8px;
  &__name {
    width: calc(100% - 48px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.campaign-performance-table {
  th,
  td {
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      text-align: right;
      > div {
        > div {
          justify-content: flex-end;
        }
      }
    }
  }
  table {
    max-height: 360px;
    overflow: auto;
    thead {
      position: sticky;
      top: 0;
    }
  }
}