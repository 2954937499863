.copy-text {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  &__button {
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
  }
}