@import '../sass/variables.scss';

.header-profile {
  position: relative;
  &__button {
    background: transparent;
    display: flex;
    border: 0;
    gap: 8px;
    align-items: center;
    outline: 0;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  &__name {
    width: 136px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__icon {
    svg {
      transition: $tr;
    }
  }
  &_opened {
    .header-profile {
      &__icon {
        svg {
          transform: rotate(180deg);
          stroke: $goldenPurplePurple5;
        }
      }
    }
  }
}

.header-profile-nav {
  transition: $tr;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  background: $grayGray1;
  box-shadow: $cardsDropShadow;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 12px;
  border: 1px solid $grayGray5;
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__link {
    display: flex;
    text-decoration: none;
    padding: 12px 14px;
    gap: 8px;
    align-items: center;
    &:hover {
      color: $secondaryColourSecondary7;
      background: $grayGray3;
    }
  }
  &__icon {
    width: 20px;
    padding: 2px;
    svg {
      width: 16px;
      height: 16px;
      display: block;
      margin: 0;
    }
  }
}
