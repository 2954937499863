@import '../../sass/variables.scss';

.campaign-step {
  padding: 24px 16px;
  border-radius: 4px;
  border: 1px solid $grayGray5;
  box-shadow: $cardsDropShadow;
  width: 210px;
  &__icon {
    max-width: 50px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}