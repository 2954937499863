.stars-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  &_align {
    &_left {
      justify-content: flex-start;
    }
  }
}