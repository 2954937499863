@import '../../../sass/variables.scss';

.campaign-stat {
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    padding: 16px;
		color: #262626;

		&__title {
			color: $secondaryColourSecondary7;
		}

    &__header {
			display: flex;
			justify-content: space-between;
    }

		&__content {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-row-gap: 8px;

			& > div:nth-child(even) {
				color: $secondaryColourSecondary7;
			}
		}
}

.overall {
	&-tr {
		background-color: #F5F5F5 !important;
	}
}

.form-select {
	&_campaigns {
		width: 220px !important;
	}
}

.performance {
	&-chart {
		max-height: 290px;
	}
}

.switcher-day {
	& .switcher-text {
		width: 85px;
		text-align: center;
	}
}