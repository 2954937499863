@import '../../sass/variables.scss';

.campaign-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font-size: 20px;
  line-height: 1.4;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.0015em;
  text-align: center;
  background: $goldenPurplePurple1;
  &_size {
    &_big {
      width: 90px;
      font-size: 45px;
      line-height: 1.16;
      height: 66px;
    }
  }
}