@import '../../sass/variables.scss';

#chartjs-tooltip {
  padding: 8px;
  border: 1px solid #d5d1d8 !important;
  background: $grayGray2;
  box-shadow: $cardsDropShadow;
  border-radius: 8px;
  max-height: initial !important;
  transition: $tr;
  font-family: 'Poppins', sans-serif !important;
  th {
    text-align: left;
    font-size: 10px;
    line-height: 1.6;
    letter-spacing: 0.005em;
    font-weight: 500;
    color: $grayGray9;
    text-transform: uppercase;
    padding-bottom: 8px;
  }
}

.chartjs-tooltip {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 0.005em;
  &__label {
    display: flex;
    align-items: center;
    gap: 4px;
    text-transform: uppercase;
    color: $secondaryColourSecondary7;
  }
  &__box {
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }
}

#chartjs-tooltip_custom {
  padding: 8px;
  border: 1px solid #d5d1d8 !important;
  background: $grayGray2;
  box-shadow: $cardsDropShadow;
  border-radius: 8px;
  max-height: initial !important;
  transition: $tr;
  font-family: 'Poppins', sans-serif !important;
  th {
    text-align: left;
    font-size: 10px;
    line-height: 1.6;
    letter-spacing: 0.005em;
    font-weight: 500;
    color: $grayGray9;
    text-transform: uppercase;
  }

  .chartjs-tooltip {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: left;
    font-size: 10px;
    line-height: 1.6;
    letter-spacing: 0.005em;
    &__label {
      display: flex;
      align-items: center;
      gap: 4px;
      text-transform: uppercase;
      color: $secondaryColourSecondary7;
    }
    &__box {
      width: 8px;
      height: 8px;
      border-radius: 2px;
    }
    &__value {
      text-transform: uppercase;
    }
  }
}