@import "../../sass/variables.scss";

.base {
  border-bottom: 1px solid $grayGray4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  width: 100%;
  background: $grayGray1;

  .tabs-nav {
    display: flex;
    gap: 24px;
    padding: 26px 32px 0 0;
    .link{ 
      text-decoration: none;
      display: block;
      padding-bottom: 6px;
      color: $secondaryColourSecondary7;
      position: relative;
      transition: $tr;

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 4px;
        border-radius: 4px;
        transition: $tr;
        opacity: 0;
        visibility: hidden;
        background: $goldenPurplePurple5;
      }

      &.active {
        color: $grayGray9;
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }

      &:hover {
        color: $grayGray9;
      }

      &.disabled {
        color: $secondaryColourSecondary3;
        pointer-events: none;

        &:hover {
          color: $secondaryColourSecondary3;
        }

        &:after {
          display: none;
        }
      }
    }
  }
}
