@import "../sass/variables.scss";

.base {
  background: url(../static/images/select-your-plan/Bg.svg) no-repeat right -261px top 305px;
  position: relative;
  min-height: 100dvh;
  // overflow: hidden;
  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &:before {
    width: 922px;
    height: 948px;
    content: "";
    display: block;
    position: absolute;
    left: -344px;
    top: -97px;
    background: url(../static/images/select-your-plan/Blob.svg) no-repeat center
      center;
  }
  .inner {
    position: relative;
    z-index: 1;
    padding-top: 16px;
    padding-bottom: 16px;

    .card {
      width: 1184px;
      margin-left: auto;
      margin-right: auto;
      min-height: 888px;
      background: $grayGray1;
      border-radius: 24px;
      padding: 24px;
      display: flex;
      height: calc(100dvh - 200px);
      position: relative;
      overflow: auto;

      .card-inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        position: relative;
        gap: 24px;
        &_justify {
          &_center {
            justify-content: center;
          }
        }
      }
    }
  }
}
