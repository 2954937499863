.base {
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 100%;
  padding-bottom: 100px;
  position: relative;

  .stepper {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.header {
  text-align: center;
}

.body {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 0 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
}
