@import '../sass/variables.scss';

.stepper {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 32px;
  counter-reset: stepper;
  &__item {
    position: relative;
    padding-top: 32px;
    counter-increment: stepper;
    width: 145px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      right: -33px;
      top: 50%;
      transform: translate(0, -50%);
      width: 34px;
      height: 8px;
      background: url('../static/images/stepper/Line 9.svg') no-repeat center center;
    }
    &:before {
      content: counter(stepper);
      display: block;
      left: 50%;
      top: 0;
      position: absolute;
      width: 24px;
      background: $grayGray6;
      border-radius: 50%;
      font-size: 14px;
      line-height: 1.71;
      color: $grayGray1;
      transform: translate(-50%, 0);
      transition: $tr;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &_active {
      &:before {
        background: $goldenPurplePurple5;
      }
    }
  }
}