@import '../../sass/variables.scss';

.border-r {
	border-right: 1px solid $grayGray5;
}

.icon-wrapper {
	display: grid;
  gap: 8px;
	grid-template-columns: 20% 1fr;
  
  & .campaign-icon {
    height: 48px;
    width: 48px;
    font-size: 28px;
  }
}

.legends {
	display: flex;
	flex-direction: column;
	gap: 8px;
}