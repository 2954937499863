@import '../sass/variables.scss';

.header-profile-image {
  width: 48px;
  height: 48px;
  background: $cyanCyan1;
  border: 1px solid $grayGray9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-profile-avatar {
  width: 48px;
  height: 48px;
  display: block;
  &__image {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    object-fit: cover;
    border-radius: 50%;
  }
}