@import "../sass/variables.scss";

.onboarding-progress {
  position: absolute;
  left: -24px;
  top: -24px;
  right: -24px;
  &__value {
    background: $goldenPurplePurple5;
    height: 12px;
    transition: $tr;
    width: 0;
  }
}