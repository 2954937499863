@import '../../sass/variables.scss';

.campaign-status {
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
  &__toggler {
    border-radius: 8px;
    display: flex;
    position: relative;
    background: $grayGray4;
    padding: 2px;

    & input.checkbox {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 3;
      cursor: pointer;
    }
  }
  &__item {
    padding: 4px 16px;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.06px;
    border-radius: 8px;
    border: 1px solid transparent;
    color: $secondaryColourSecondary4;
    &_current {
      font-weight: 500;
    }
    &.campaign-status {
      &__item {
        &_active {
          &.campaign-status {
            &__item {
              &_current {
                color: $polarGreenGreen6;
                border-color: $polarGreenGreen6;
                background: $polarGreenGreen2;
              }
            }
          }
        }
        &_paused {
          &.campaign-status {
            &__item {
              &_current {
                color: $dustRedRed6;
                border-color: $dustRedRed6;
                background: #ffccc780;
              }
            }
          }
        }
      }
    }
  }
}