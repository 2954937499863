@import "../../../sass/variables.scss";

.base {
  border: 1px solid $grayGray5;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;

  .header {
    display: flex;
    gap: 32px;
    justify-content: space-between;
  }

  .placeholder {
    min-height: 412px;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
