@import '../sass/variables.scss';

.banner {
  padding: 24px 32px;
  border-radius: 8px;
  background: $sunriseYellowYellow1;
  position: relative;
  &__image {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .complete-progress {
    position: absolute;
    right: 32px;
    top: 24px;
  }
  &_color {
    &_cyan-cyan-1 {
      background: $cyanCyan1;
    }
    &_volcano-volcano-1 {
      background: $volcanoVolcano1;
    }
  }
  &__emoji {
    position: absolute;
    right: 17px;
    bottom: -73px;
    font-size: 99px;
    line-height: 2.72;
  }
  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 4px;
    background: transparent;
    outline: 0;
    z-index: 1;
    border: 0;
  }
}