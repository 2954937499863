.onboarding {
  background: url(../static/images/select-your-plan/Bg.svg) no-repeat right -261px top 305px;
  position: relative;
  min-height: 100dvh;
  overflow: hidden;
  &_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &:before {
    width: 922px;
    height: 948px;
    content: '';
    display: block;
    position: absolute;
    left: -344px;
    top: -97px;
    background: url(../static/images/select-your-plan/Blob.svg) no-repeat center center;
  }
  &__inner {
    position: relative;
    z-index: 1;
  }
  &-back {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}