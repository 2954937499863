@import "../../sass/variables.scss";

.notification {
  border: 1px solid $polarGreenGreen6;
  background: $sunriseYellowYellow1;
  padding: 24px;
  display: flex;
  gap: 16px;
  border-radius: 8px;
  z-index: 3;
  width: 600px;
  align-items: center;
  color: $secondaryColourSecondary7;
  font-family: 'Poppins', sans-serif;

  &_error {
    border-color: $dustRedRed6;
  }

  .title {
    color: $grayGray9;
  }

  &__icon {
    padding: 2px;

    svg {
      display: block;
    }
  }
}