@import "variables.scss";

.toggle-password-input {
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &__icon {
    width: 24px;
    height: 24px;
    display: block;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
  &_with-label {
    margin-top: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      display: block;
    }
  }
}