@import "../../../sass/variables.scss";

.selected-products {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 300px;
  overflow: auto;
}

.type-divider {
  margin: 0 -9px;
}

.products {
  overflow: auto;
  display: flex;
  gap: 8px;
  flex-direction: column;
  max-height: 215px;

  label {
    display: flex;
    align-items: center;
    min-height: 48px;
  }
}
