@import '../sass/variables.scss';

.select-your-plan {
  background: url(../static/images/select-your-plan/Bg.svg) no-repeat right -261px top 217px;
  position: relative;
  min-height: 100dvh;
  &:before {
    width: 922px;
    height: 948px;
    content: '';
    display: block;
    position: absolute;
    left: -344px;
    top: -185px;
    background: url(../static/images/select-your-plan/Blob.svg) no-repeat center center;
  }
  &__inner {
    position: relative;
    z-index: 1;
  }
}

.syp-head {
  display: flex;
  gap: 24px;
  &__col {
    width: calc(50% - 12px);
  }
}

.syp-card {
  padding: 32px;
  box-shadow: $cardsDropShadow;
  background: $grayGray1;
  border-radius: 8px;
  &__row {
    display: flex;
    align-items: center;
    gap: 32px;
  }
  &__slider {
    width: 55.73%;
  }
}

.syp-slider {
  height: 24px;
  .track {
    border: 1px solid $secondaryColourSecondary4;
    background: $grayGray3;
    border-radius: 100px;
    height: 24px;
  }
  .track-0 {
    background: $calendulaGoldGold4;
  }
  .thumb {
    cursor: grab;
    width: 30px;
    height: 30px;
    background: url(../static/images/select-your-plan/akar-icons_chevron-horizontal.svg) no-repeat center center $calendulaGoldGold4;
    border-radius: 50%;
    border: 1px solid $secondaryColourSecondary4;
    top: -3px;
    left: -16px;
    outline: 0;
  }
}

.syp-emails {
  display: flex;
  gap: 8px;
  align-items: center;
  &__count {
    text-align: center;
    border: 0;
    outline: 0;
    border-bottom: 2px solid $calendulaGoldGold5;
    -moz-appearance: textfield;
    height: 28px;
    width: 80px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &__label {
    white-space: nowrap;
  }
}

.pricing-table {
  background: $grayGray2;
  position: relative;
  box-shadow: $cardsDropShadow;
  &__head {
    display: flex;
    background: $grayGray1;
    border-bottom: 1px solid $grayGray5;
    position: sticky;
    top: 88px;
  }
  &__button {
    padding: 10px 32px;
    min-width: initial;
    box-shadow: $buttonBrutal;
    &:hover {
      transform: translate(0, 4px);
      box-shadow: none;
    }
  }
  &__col {
    width: 18.75%;
    padding: 24px;
    border-left: 1px solid $grayGray4;
    border-bottom: 1px solid $grayGray4;
    min-height: 68px;
    padding: 16px 24px;
    &:first-child {
      width: 25%;
      border-left: 0;
    }
    &_title {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 0;
    }
    &_head {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: space-between;
      border-bottom: 0;
      padding: 24px;
      &:first-child {
        border-bottom: 0;
      }
    }
  }
  &__row {
    display: flex;
    &_section {
      background: $grayGray1;
      position: sticky;
      top: 343px;
      .pricing-table {
        &__col {
          padding-top: 24px;
          padding-bottom: 24px;
        }
      }
    }
  }
  &__service-name {
    display: flex;
    gap: 4px;
    align-items: flex-start;
  }
  &__tooltip-toggle {
    cursor: pointer;
  }
  &__tooltip-wrapper {
    .pricing-table {
      &__tooltip {
        background: $grayGray1;
        color: $secondaryColourSecondary7;
        box-shadow: $cardsDropShadow;
        padding: 16px;
        border-radius: 16px;
        border: 1px solid $grayGray5;
        width: 320px;
        transition: opacity 0.2 ease-in-out, visibility 0.2s ease-in-out !important;
        visibility: visible;
        &.react-tooltip {
          &__show {
            opacity: 1 !important;
            visibility: visible;
          }
          &__arrow {
            display: none;
          }
        }
      }
    }
  }
  &__price-section {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1179px) {
  .pricing-table {
    &__row {
      &_section {
        top: 363px;
      }
    }
  }
}

@media only screen and (max-width: 1074px) {
  .pricing-table {
    &__row {
      &_section {
        top: 387px;
      }
    }
  }
}

@media only screen and (max-width: 1032px) {
  .pricing-table {
    &__row {
      &_section {
        top: 422px;
      }
    }
  }
}

@media only screen and (max-width: $mobileWidth) {
  .syp-head {
    display: block;
    &__col {
      width: auto;
    }
  }
  .syp-card {
    padding: 24px 24px 28px;
    &__content {
      padding: 8px;
    }
    &__row {
      flex-direction: column;
      gap: 20px;
    }
    &__emails {
      order: 1;
      padding: 6px 0;
    }
    &__slider {
      order: 2;
      width: 100%;
    }
  }
  .pricing-table {
    &__head {
      flex-wrap: wrap;
      border: 0;
      top: 72px;
    }
    &__col {
      width: 25%;
      order: 1;
      min-height: 60px;
      background: $grayGray1;
      &_title {
        order: 2;
        border-top: 1px solid $grayGray4;
        padding-top: 24px;
        padding-bottom: 24px;
      }
      &:first-child {
        width: 100%;
      }
      &_head {
        padding: 16px 4px;
        gap: 16px;
      }
      .akar-icon {
        margin: 0 auto;
      }
    }
    &__emails-per-month {
      font-size: 10px !important;
      line-height: 1.5 !important;
    }
    &__service-name {
      width: 100%;
      justify-content: center;
      padding-top: 18px;
      padding-bottom: 18px;
      background: transparent;
    }
    &__row {
      flex-wrap: wrap;
      &_section {
        position: static;
      }
    }
    &__button {
      padding: 4px 8px;
    }
    &__price-section {
      gap: 16px;
    }
  }
  .select-your-plan {
    background: transparent;
    min-height: initial;
    &:before {
      display: none;
    }
  }
}