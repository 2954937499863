@import '../../sass/variables.scss';

.toogler-wrap {
	display: flex;
	gap: 8px;
	align-items: center;
	white-space: nowrap;

	&.secondary {
		& .toogler-wrap {
			&__item {
				&_active {
					color: $goldenPurplePurple5;
					border-color: $goldenPurplePurple5;
					background: white;
				}
			}
		}
	}

	&__toggler {
		border-radius: 8px;
		display: flex;
		position: relative;
		background: $grayGray4;
		padding: 2px;
	}

	&__item {
		padding: 4px 16px;
		font-size: 12px;
		line-height: 1.67;
		letter-spacing: 0.06px;
		border-radius: 8px;
		border: 1px solid transparent;
		cursor: pointer;
		color: $secondaryColourSecondary4;

		&_current {
			font-weight: 500;
		}

		&.toogler-wrap {
			&__item {
				&_active {
					color: white;
					border-color: $goldenPurplePurple5;
					background: $goldenPurplePurple5;
				}
			}
		}
	}
}