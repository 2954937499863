@import "../../sass/variables.scss";

.form-control {
  position: relative;
  &__input {
    padding: 12px 14px;
    display: block;
    width: 100%;
    outline: 0;
    border-radius: 4px;
    border: 1px solid $grayGray5;
    transition: $tr;
    color: $secondaryColourSecondary7;
    background: $grayGray1;
    &::placeholder {
      color: $secondaryColourSecondary4;
      opacity: 1;
    }
    &:hover,
    &:focus {
      border-color: $goldenPurplePurple5;
    }
    &_password {
      padding-right: 52px;
    }
    &_select {
      -webkit-appearance: none;
      background: url(../../static/images/sign-up/Icon.svg) no-repeat right 14px
        center $grayGray1;
    }
    &_date {
      background: url(../../static/images/widgets/Icon.svg) no-repeat right 14px
        center $grayGray1;
    }
    &_error {
      border-color: $dustRedRed5 !important;
    }
    &_textarea {
      resize: none;
      height: 220px;
    }
    &[type="number"] {
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    &_size {
      &_md {
        padding: 8px 14px;
        font-size: 14px;
        line-height: 1.71;
      }
      &_small {
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: 0.06px;
        padding: 6px 14px;
        width: 150px;
      }
      &_xsmall {
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: 0.06px;
        padding: 4px 14px;
        width: 150px;
      }
    }
    &[disabled] {
      border-color: $grayGray5 !important;
      color: $grayGray5 !important;
      &::placeholder {
        color: $grayGray5;
      }
    }
    &_search {
      background: url(../../static/images/forms/akar-icons_search.svg) no-repeat
        left 14px center;
      padding-left: 46px;
    }
  }
  &_error {
    .form-control {
      &__input {
        border-color: $dustRedRed5;
      }
    }
  }
  &__toggle-password-input {
    position: absolute;
    right: 14px;
    top: 14px;
    &_with-label {
      top: 50px;
    }
  }
  &__error {
    margin-top: 4px;
    padding-left: 14px;
    color: $dustRedRed5;
  }
  &__label {
    display: block;
    &_disabled {
      color: $grayGray5;
    }
  }
  &__radio-wrapper {
    display: flex;
    gap: 24px;
    &_block {
      gap: 8px;
      flex-direction: column;

      &_2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      &_3 {
        display: grid;
        grid-template-columns: repeat(3 1fr);
      }
      &_4 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
      &_gap {
        &_16 {
          gap: 16px;
        }
        &_8 {
          gap: 8px;
        }
        &_4 {
          gap: 4px;
        }
      }
    }
  }

  &__radio {
    &[data-variant="pill"] {

      label {
        padding: 12px 32px 12px 16px;
        border: 1px solid $grayGray5;
        box-shadow: $cardsDropShadow;
        border-radius: 8px;
        transition: $tr;
        &:hover {
          border-color: $goldenPurplePurple5;
        }

        span {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        &:before {
          left: auto;
          right: 8px;
        }
      }
      .form-control {
        &__r-input {
          &:checked {
            + .form-control {
              &__r-label {
                &_radio {
                  border-color: $goldenPurplePurple5;
                  background: $layoutBg;
                }
              }
            }
          }
        }
      }
    }
  }

  &__radio,
  &__checkbox {
    position: relative;
  }
  &__r-input {
    opacity: 0;
    width: 0;
    position: absolute;
    &:checked {
      + .form-control {
        &__r-label {
          &:before {
            background-color: $goldenPurplePurple5;
            background-image: url(../../static/images/forms/Vector.svg);
            border-color: $goldenPurplePurple5;
          }
        }
      }
    }
  }
  &__r-label {
    position: relative;
    padding-left: 26px;
    display: block;
    cursor: pointer;
    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      border: 1px solid $grayGray5;
      transition: $tr;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &_radio {
      &:before {
        border-radius: 50%;
      }
    }
    &_empty {
      padding-right: 16px !important;
      &:before {
        display: none;
      }
    }
    &_p {
      &_4 {
        &_8 {
          padding: 3px 7px !important;
          background: $layoutBg !important;
          border-color: $goldenPurplePurple5 !important;
        }
      }
    }
    &:hover {
      &:before {
        border-color: $goldenPurplePurple5;
      }
    }
  }
  &__inner {
    position: relative;
  }
  &__append {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: $grayGray6;
    color: $grayGray1;
    left: 50%;
    padding: 14px 14px 14px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: hidden;
    color: $grayGray1;
  }
  &__error-icon {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translate(0, -50%);
    svg {
      display: block;
    }
  }
  .calendar_wrapper {
    position: fixed;
    z-index: 1;

    & .rdrCalendarWrapper {
      padding: 8px 16px;
      border-radius: 8px;
      width: 100%;
      border: 1px solid $grayGray5;
      background: $grayGray1;
      box-shadow: $cardsDropShadow;
      margin-top: 4px;
    }
  }
}
