@import '../sass/variables.scss';

.preloader {
  position: fixed;
  width: 64px;
  height: 64px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 2;
  &__icon {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }
  &_absolute {
    position: absolute;
  }
  &_overflow {
    background: rgb(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    transform: none;
    &-opaque {
      background: rgba(255, 255, 255);
    }
    .preloader {
      &__icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 64px;
        height: 64px;
        margin-top: -32px;
        margin-left: -32px;
      }
    }
  }
  &_br_24 {
    border-radius: 24px;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}