@import '../sass/variables.scss';

.wysiwyg {
  position: relative;
  border-radius: 4px;
  border: 1px solid $grayGray5;
  &__generate {
    position: absolute;
    right: 8px;
    top: 3px;
  }
  .rdw-editor-toolbar {
    display: flex;
    padding: 0;
    margin: 0;
    border: 0;
    border-bottom: 1px solid $grayGray5;
    > div {
      padding: 6px 8px;
      margin: 0;
      border-right: 1px solid $grayGray5;
      &[aria-label="rdw-block-control"] {
        padding: 2px 0;
      }
      &:last-child {
        border: 0;
      }
    }
  }
  .rdw-option-wrapper {
    width: 24px;
    height: 24px;
    border: 0;
    padding: 0;
    cursor: pointer;
    &:hover {
      box-shadow: none;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .public-DraftStyleDefault-ltr {
    margin: 0;
    color: $secondaryColourSecondary4;
    font-size: 14px;
    line-height: 1.71;
    transition: $tr;
  }
  .rdw-dropdown-wrapper {
    width: 147px;
    padding: 4px 56px 4px 16px;
    background: url('../static/images/store-settings/akar-icons_chevron-down.svg') no-repeat right 16px center;
    border: 0;
    box-shadow: none;
  }
  .rdw-dropdown-selectedtext {
    padding: 0;
    font-size: 14px;
    line-height: 1.71;
    color: $secondaryColourSecondary7;
  }
  .rdw-dropdown-carettoopen,
  .rdw-dropdown-carettoclose {
    display: none;
  }
  .DraftEditor-editorContainer {
    padding: 14px;
  }
  &_edited {
    .public-DraftStyleDefault-ltr {
      color: $secondaryColourSecondary7;
    }
  }
  .rdw-link-modal {
    padding: 24px;
    border-radius: 4px;
    border: 1px solid $grayGray5;
    box-shadow: $cardsDropShadow;
    width: 400px;
    top: 40px;
    height: auto;
  }
  .rdw-link-modal-label {
    font-size: 14px;
    line-height: 1.71;
    font-weight: 500;
  }
  .rdw-link-modal-input {
    margin-top: 4px;
    margin-bottom: 24px;
    padding: 14px;
    font-size: 14px;
    line-height: 1.71;
    display: block;
    border-color: $grayGray6;
    border-radius: 4px;
    height: 52px;
  }
  .rdw-link-modal-target-option {
    position: relative;
    input {
      opacity: 0;
      width: 0;
      &:checked {
        + span {
          &:before {
            background-color: $goldenPurplePurple5;
            background-image: url(../static/images/forms/Vector.svg);
          }
        }
      }
    }
    span {
      padding-left: 26px;
      position: relative;
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      line-height: 1.71;
      letter-spacing: -0.14px;
      color: $secondaryColourSecondary7;
      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border: 1px solid $grayGray5;
        left: 0;
        top: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        transform: translate(0, -50%);
        border-radius: 2px;
        transition: $tr;
      }
    }
  }
  .rdw-link-modal-btn {
    text-align: center;
    border: 0;
    outline: 0;
    text-decoration: none;
    background: $goldenPurplePurple5;
    color: $grayGray1;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    transition: $tr;
    width: auto;
    height: auto;
    padding: 4px 16px;
    font-size: 14px;
    border-radius: 8px;
    line-height: 1.71;
    &[disabled] {
      background: $grayGray6;
      box-shadow: none;
      cursor: not-allowed;
      &:hover {
        background: $grayGray6;
      }
    }
    &:last-child {
      color: $goldenPurplePurple5;
      background: transparent;
      border: 1px solid $goldenPurplePurple5;
      &:hover,
      &:active {
        color: $goldenPurplePurple7;
        border-color: $goldenPurplePurple7;
        background: transparent;
        &[disabled] {
          color: $grayGray6;
          background: transparent;
          border-color: $grayGray6;
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}