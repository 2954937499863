@import '../../../sass/variables.scss';

.generate-button {
  padding: 4px 0 4px 14px;
  font-size: 12px;
  color: $goldenPurplePurple5;
  line-height: 1.67;
  letter-spacing: 0.06px;
  text-decoration: underline;
  transition: $tr;
  display: inline-block;
  border: 0;
  background: transparent;
  outline: 0;
  transition: $tr;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &[disabled] {
    opacity: 0.5;
  }
}