@import '../sass/variables.scss';

.complete-task {
  padding: 5px 16px 5px 8px;
  box-shadow: $cardsDropShadow;
  border-radius: 8px;
  border: 1px solid $goldenPurplePurple5;
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
  transition: $tr;
  position: relative;
  &_completed {
    cursor: default;
    background: $layoutBg;
  }
  &:hover,
  &_active {
    background: $layoutBg;
  }
}