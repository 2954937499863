@import '../sass/variables.scss';

.timeline-item {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 8px 0;
  position: relative;
  max-height: 42px;
  &__date {
    width: 64px;
    white-space: nowrap;
  }
  &__icon {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background: $primaryColourBranding5;
    &_gold {
      background: $calendulaGoldGold3;
    }
  }
  &__action {
    display: flex;
    width: calc(100% - 112px);
    justify-content: space-between;
    gap: 16px;
  }
  &:not(:first-child) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 38px;
      background: url(../static/images/performance/Line.svg) no-repeat center center;
      top: -27px;
      left: 80px;
    }
  }
  &__link {
    text-decoration: none;
  }
}