@import "../../sass/variables.scss";

.base {
  height: 56px;
  border-radius: 4px;
  border: 1px solid $grayGray5;
  transition: $tr;
  color: $secondaryColourSecondary7;
  background: $grayGray1;
  display: flex;
  align-items: center;
  padding: 0 14px 0 14px;
  position: relative;
  gap: 8px;
  font-size: 12px;
  line-height: 1.71;
  width: 100%;
  white-space: nowrap;

  .addon {
    font-size: 20px;
    padding: 0 4px;
    line-height: 1.5;
  }

  .time {
    color: $secondaryColourSecondary4;
    font-size: 12px;
    line-height: 1.67;
  }

  &.has-addon {
    padding: 0 8px 0 4px;
  }

  &::placeholder {
    color: $secondaryColourSecondary4;
    opacity: 1;
  }

  &:hover,
  &:focus {
    border-color: $goldenPurplePurple5;
  }

  input {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
}
