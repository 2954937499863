@import '../../sass/variables.scss';

.ui-dropdown {
  position: relative;
  width: auto;
  display: inline-block;

  & .content {
    position: absolute;
    width: 500px;
    right: 0;
    margin-top: 5px;
    z-index: 1;

    & ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      max-height: 250px;
      overflow-y: auto;
      z-index: 2;
      background-color: #ffffff;
      box-shadow: $cardsDropShadow;
      border-radius: 4px;
      border: 1px solid $grayGray5;

      & li {
        padding: 8px 14px;
        font-size: 14px;
        color: $secondaryColourSecondary7;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.item {
          cursor: pointer;
          transition: background-color .2s;
          &:hover {
            background-color: $grayGray3;
          }
        }
      }
    }
  }
}