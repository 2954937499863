$grayGray1: #ffffff;
$grayGray2: #fafafa;
$grayGray3: #f5f5f5;
$grayGray4: #e8e8e8;
$grayGray5: #d9d9d9;
$grayGray6: #bfbfbf;
$grayGray8: #595959;
$grayGray9: #262626;
$grayGray10: #000000;
$layoutBg: #f0f2f5;
$goldenPurplePurple1: #f9f0ff;
$goldenPurplePurple2: #efdbff;
$goldenPurplePurple3: #d3adf7;
$goldenPurplePurple4: #b37feb;
$goldenPurplePurple5: #9254de;
$goldenPurplePurple6: #722ed1;
$goldenPurplePurple7: #531dab;
$secondaryColourSecondary2: #d5d1d8;
$secondaryColourSecondary3: #c0bac5;
$secondaryColourSecondary4: #aba3b1;
$secondaryColourSecondary5: #968c9d;
$secondaryColourSecondary6: #81758a;
$secondaryColourSecondary7: #6c5e77;
$secondaryColourSecondary8: #574763;
$dustRedRed5: #ff4d4f;
$dustRedRed6: #f5222d;
$magentaMagenta4: #ff85c0;
$calendulaGoldGold3: #ffe58f;
$calendulaGoldGold4: #ffd666;
$calendulaGoldGold5: #ffc53d;
$cyanCyan1: #e6fffb;
$polarGreenGreen2: #d9f7be;
$polarGreenGreen6: #52c41a;
$sunriseYellowYellow1: #feffe6;
$sunriseYellowYellow7: #d4b106;
$primaryColourBranding2: #caf3ff;
$primaryColourBranding5: #4bd4fb;
$geekBlueGeekBlue2: #d6e4ff;
$geekBlueGeekBlue3: #adc6ff;
$geekBlueGeekBlue5: #597ef7;
$geekBlueGeekBlue6: #2f54eb;
$volcanoVolcano1: #fff2e8;
$volcanoVolcano2: #ffd8bf;
$volcanoVolcano3: #ffbb96;
$volcanoVolcano5: #ff7a45;
$magentaMagenta1: #fff0f6;
$magentaMagenta2: #ffd6e7;
$magentaMagenta3: #ffadd2;
$magentaMagenta5: #f759ab;
$calendulaGoldGold2: #fff1b8;
$calendulaGoldGold5: #ffc53d;
$calendulaGoldGold7: #d48806;
$cardsDropShadow: 2px 2px 8px 0px #cec2d680;
$buttonBrutal: 0px 4px 0px 0px #000000;
$dropShadow: 2.4px 2.4px 9.6px 0 #cec2d680;
$tr: all 0.2s ease-in-out;
$mobileWidth: 991px;
$topbarHeight: 81px;
$tabNavHeight: 56px;
$trans3: 0.3s;
