@import '../../sass/variables.scss';

.employee {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  &__data {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__icon {
    display: flex;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid $grayGray10;
    background: $magentaMagenta1;
    &_admin {
      background: $primaryColourBranding2;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      margin: 0;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}