@import '../../sass/variables.scss';

.view-cart {
  outline: 0;
  padding: 0;
  color: $grayGray5;
  border: 0;
  background: transparent;
  cursor: pointer;
  transition: $tr;
  &:hover {
    color: $goldenPurplePurple5;
  }
}

.cart-table {
  th {
    color: $grayGray9;
    &:hover {
      color: $grayGray9;
    }
    > div {
      text-align: right;
    }
    &:first-child {
      > div {
        text-align: center;
      }
    }
  }
  td {
    min-height: 72px;
  }
  table {
    max-height: calc(90vh - 225px);
  }
}