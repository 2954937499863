@import '../../sass/variables.scss';

.ui-h2-lg {
  font-weight: 700;
  font-size: 45px;
  line-height: 1.16;
  margin: 0;
}

.ui-h3-lg {
  font-weight: 700;
  font-size: 36px;
  line-height: 1.22;
  margin: 0;
}

.ui-other-headings-h5-bold {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0;
}

.ui-email-templates-h1-semibold {
  margin: 0;
  font-size: 34px;
  line-height: 1.18;
  font-weight: 600;
}

.ui-email-templates-h1-bold {
  margin: 0;
  font-size: 34px;
  line-height: 1.18;
  font-weight: 700;
}

.ui-other-headings-h4-bold {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.0025em;
  margin: 0;
}

.ui-other-headings-h5-semibold {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}

.ui-email-templates-subtitle-1-semibold {
  margin: 0;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;
}

.ui-email-templates-subtitle-2-bold {
  margin: 0;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
}

@media only screen and (max-width: $mobileWidth) {
  .ui-h3-lg-mobile {
    font-size: 36px;
    line-height: 1.22;
    font-weight: 600;
  }
}