.overview-emails {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  &__count {
    font-size: 21px;
    line-height: 1.33;
    letter-spacing: 0.054px;
    font-weight: 600;
    font-weight: 600;
  }
  &__label {
    font-size: 10px;
    line-height: 1.71;
  }
}

.overview-total-emails-sent {
  height: 312px;
}