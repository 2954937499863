@import '../sass/variables.scss';

.download-plugin {
  max-width: 624px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  counter-reset: download-plugin;
  gap: 16px;
  &__step {
    flex-grow: 1;
    flex-basis: 0;
    counter-increment: download-plugin;
    padding: 24px 16px;
    border-radius: 4px;
    border: 1px solid $grayGray5;
  }
  &__title {
    &:before {
      content: counter(download-plugin) '. ';
    }
  }
}