@import '../../sass/variables.scss';

.anom-table-wrapper {
  border-radius: 4px;
  border: 1px solid $grayGray5;
}

.anom-table {
  width: 100%;
  border-collapse: collapse;
  th {
    padding: 12px 8px;
    border-bottom: 1px solid $secondaryColourSecondary4;
  }
  td {
    padding: 24px 8px;
    border-bottom: 1px solid $grayGray4;
  }
  tr {
    &:last-child {
      td {
        border-bottom: 0;;
      }
    }
  }
  &__th {
    &_w {
      &_33 {
        width: 33.3333333%;
      }
    }
  }
}