@import '../sass/variables.scss';

.shop-import {
  max-width: 100%;
  width: 624px;
  margin-left: auto;
  margin-right: auto;
  &__platforms {
    display: flex;
    gap: 24px;
  }
}

.shop-platform {
  flex-grow: 1;
  flex-basis: 0;
  padding: 23px;
  border-radius: 8px;
  background: $grayGray1;
  border: 1px solid $grayGray5;
  cursor: pointer;
  box-shadow: $cardsDropShadow;
  transition: $tr;
  &:hover,
  &_active {
    &:not([disabled]) {
      background: $layoutBg;
      border-color: $goldenPurplePurple5;
    }
  }
  &__icon {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  &[disabled] {
    cursor: not-allowed;
  }
}

.shop-import-url-wrapper {
  border: 1px solid $grayGray5;
  display: flex;
  gap: 24px;
  border-radius: 8px;
  align-items: center;
  padding: 24px;
  &__button {
    width: 140px;
  }
}

.shop-meta {
  display: flex;
  gap: 8px;
  align-items: center;
  width: calc(100% - 164px);
  &__icon {
    width: 64px;
    height: 64px;
    border-radius: 4px;
  }
}