@import '../sass/variables.scss';

.notifications {
  position: relative;
  &__button {
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
    display: block;
    position: relative;
    cursor: pointer;
  }
  &__icon {
    width: 32px;
    height: 32px;
    display: block;
    margin: 0;
  }
  &__count {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $dustRedRed6;
    background: $dustRedRed5;
    font-size: 8px;
    line-height: 0.005em;
    line-height: 1.5;
    color: $grayGray1;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
  }
}