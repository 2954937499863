@import "../../sass/variables.scss";

.ui-button {
  padding: 16px;
  text-align: center;
  border: 0;
  outline: 0;
  text-decoration: none;
  background: $goldenPurplePurple5;
  color: $grayGray1;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.01em;
  border-radius: 40px;
  cursor: pointer;
  display: inline-block;
  transition: $tr;
  --border-radius: 8px;

  &_icon {
    display: flex;
    align-items: center;
    gap: 8px;
    &_gap {
      &_4 {
        gap: 4px;
        .ui-button {
          &__icon {
            padding: 0;
          }
        }
      }
    }
  }
  &_medium {
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: var(--border-radius);
  }
  &_small {
    padding: 4px 16px;
    font-size: 14px;
    border-radius: var(--border-radius);
    line-height: 1.71;
  }
  &_extra-small {
    padding: 4px 16px;
    font-size: 12px;
    border-radius: var(--border-radius);
    line-height: 1.33;
    font-weight: 500;
    letter-spacing: 0.5%;
  }
  &:hover,
  &:active {
    background: $goldenPurplePurple7;
    color: $grayGray1;
  }
  &_mw {
    &_120 {
      min-width: 120px;
    }
    &_132 {
      min-width: 132px;
    }
    &_150 {
      min-width: 150px;
    }
    &_180 {
      min-width: 180px;
    }
    &_200 {
      min-width: 200px;
    }
    &_210 {
      min-width: 210px;
    }
    &_220 {
      min-width: 220px;
    }
  }
  &[disabled],
  &_disabled {
    background: $grayGray6;
    box-shadow: none;
    cursor: not-allowed;
    &:hover {
      background: $grayGray6;
    }
  }
  &_secondary {
    color: $goldenPurplePurple5;
    background: transparent;
    border: 1px solid $goldenPurplePurple5;
    &:hover,
    &:active {
      color: $goldenPurplePurple7;
      border-color: $goldenPurplePurple7;
      background: transparent;
      svg {
        stroke: $goldenPurplePurple7;
      }
    }
    &[disabled],
    &.ui-button_disabled {
      color: $grayGray6;
      background: transparent;
      border-color: $grayGray6;
      svg {
        stroke: $grayGray6;
      }
      &:hover {
        background: transparent;
      }
    }
  }
  &_no-border {
    border: 1px solid transparent;
    color: $goldenPurplePurple5;
    background: transparent;
    &:hover,
    &:active {
      color: $goldenPurplePurple7;
      border-color: transparent;
      background: transparent;
      svg {
        stroke: $goldenPurplePurple7;
      }
    }
    &[disabled] {
      color: $grayGray6;
      background: transparent;
      border-color: transparent;
      svg {
        stroke: $grayGray6;
      }
    }
  }
  &_arrow {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    gap: 8px;
    font-weight: 500;
    align-items: center;
    svg {
      display: block;
    }
  }
  &_full-width {
    width: 100%;
    display: block;
  }
  &__icon {
    padding: 4px;
    svg {
      display: block;
    }
  }
  &_add {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.06px;
    font-weight: 500;
  }
  &_arrow {
    display: flex;
    padding: 0;
    gap: 0;
    .ui-button {
      &__text {
        padding: 8px 17px;
      }
      &__icon {
        padding: 8px 16px;
        border-left: 1px solid $grayGray1;
      }
    }
  }
}
