.title {
    &.raffle-title {
        display: flex;
        align-items: center;
        & > span {
            margin-right: 10px;
        }

        & > .chips {
            font-weight: 400;
        }
    }
}