@import '../../sass/variables.scss';

.an-table {
  th {
    padding: 0;
    transition: $tr;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1.6;
    font-weight: 500;
    letter-spacing: 0.005em;
    border-color: $grayGray6;
    transition: $tr;
    &:hover {
      color: $goldenPurplePurple5;
    }
    > div {
      padding: 12px 8px;
      > div {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
      }
    }
    .active {
      color: $goldenPurplePurple5;
      font-weight: 500;
    }
    &.stiff {
      > div {
        padding: 11px 0 11px 8px;
      }
    }
  }
  td {
    position: relative;
    padding: 12px 8px;
    font-size: 14px;
    line-height: 1.71;
    color: $secondaryColourSecondary7;
    border-color: $grayGray5 !important;
    a {
      text-decoration: none;
    }
    &.stiff {
      padding: 3px 0 11px 8px;
    }
  }
  tr {
    transition: $tr;
    &:last-child {
      td {
        border-bottom: 1px solid $grayGray5;
      }
    }
    &.row-select-selected,
    &.row-select-single-selected {
      background: transparent;
      font-weight: normal;
    }
    &.row-select-clickable {
      cursor: default;
    }
  }
  &_overflow {
    &_visible {
      overflow: initial;
      table {
        overflow: initial;
      }
      td {
        div {
          overflow: initial;
        }
      }
    }
  }
  &_mh {
    &_72 {
      td {
        min-height: 72px;
      }
    }
  }
  &__bottom {
    padding: 8px 24px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
  &__page-size {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &_survey {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__hidden-row {
    pointer-events: none;
    > td {
      opacity: 0.5;
    }
    .ui-base-checkbox {
      &__input {
        &:checked {
          + .ui-base-checkbox {
            &__label {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}