@import '../sass/variables.scss';

.widget-layout-item {
  padding: 8px;
  border-radius: 4px;
  background: $grayGray1;
  box-shadow: $cardsDropShadow;
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid $grayGray5;
  transition: $tr;
  &:hover {
    border-color: $goldenPurplePurple3;
  }
  &.active {
    border-color: $goldenPurplePurple5;
    background: $layoutBg;
  }
  &.custom-links {
    flex-grow: initial;
    flex-basis: initial;
  }
  &.products {
    flex-grow: initial;
    flex-basis: initial;
    width: 336px;
  }
  &.blog-post {
    flex-grow: initial;
    flex-basis: initial;
    width: 500px;
  }
  &.shop-information {
    flex-grow: initial;
    flex-basis: initial;
    width: 640px;
  }
  &.trust-factors {
    flex-grow: initial;
    flex-basis: initial;
    width: 479px;
  }
}