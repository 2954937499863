@import "../../../sass/variables.scss";

.base {
  padding: 8px 2px 0;
  border-top: 1px solid $grayGray5;
  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    height: 32px;
    font-weight: 500;
    width: 70px;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.06px;
  }
}
