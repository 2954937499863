@import "../sass//variables.scss";

.base {
  overflow: auto;

  &.full-height {
    height: 100%;

    .inner {
      height: 100%;
    }
  }

  .inner {
    width: 1184px;
    margin: 0 auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
  }
}
