@import '../sass/variables.scss';

.payment-method {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $grayGray5;
  transition: $tr;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
  &_default {
    background: $grayGray2;
  }
  &__card {
    width: 44px;
    height: 34px;
    border-radius: 4px;
    border: 1px solid $grayGray4;
    background: $grayGray1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__info {
    display: flex;
    gap: 16px;
  }
  &:hover {
    border-color: $goldenPurplePurple5;
  }
  &__actions {
    position: relative;
  }
}