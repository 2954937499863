@import '../sass/variables.scss';

.nav-item {
  &__link {
    display: block;
    padding: 8px 16px;
    color: $grayGray1;
    text-decoration: none;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    &:hover {
      color: $grayGray1;
      background: $goldenPurplePurple5;
    }
    &_active {
      background: $goldenPurplePurple5;
      .nav-item {
        &__chevron {
          transform: rotate(180deg);
        }
      }
      + .nav-item {
        &__subnav {
          display: flex;
        }
      }
    }
  }
  &__subnav {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    gap: 2px;
  }
  &__sublink {
    padding: 4px 16px 4px 32px;
    display: block;
    color: $grayGray1;
    transition: $tr;
    text-decoration: none;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: $grayGray1;
    }
    &_active {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  &__text-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__chevron {
    padding: 4px;
    svg {
      width: 16px;
      height: 16px;
      display: block;
      margin: 0;
    }
  }
}