@import '../sass/variables.scss';

.notification-item  {
  padding: 8px 16px;
  transition: $tr;
  position: relative;
  display: flex;
  gap: 8px;
  cursor: pointer;
  &:hover {
    background: $grayGray3;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 0;
    background: $grayGray4;
    height: 1px;
  }
  &__avatar {
    padding-top: 6px;
  }
  &__avatar-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: 0;
  }
  &_unread {
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $goldenPurplePurple5;
      position: absolute;
      top: 14px;
      right: 16px;
    }
  }
}