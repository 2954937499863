@import '../sass/variables.scss';

.onboarding-survey {
  position: relative;
  z-index: 1;
  &__card {
    max-width: 1184px;
    margin-left: auto;
    margin-right: auto;
    background: $grayGray1;
    border-radius: 24px;
    padding: 24px;
    display: flex;
    min-height: calc(100dvh - 200px);
    position: relative;
    overflow: hidden;
  }
}