@import '../sass/variables.scss';

.widgets-list {
  margin-left: auto;
  margin-right: auto;
  max-width: 696px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  .widget-button {
    width: calc(50% - 8px);
    transition: $tr;
    &:hover {
      border-color: $goldenPurplePurple4;
    }
    &_active {
      border-color: $goldenPurplePurple5;
      background: $layoutBg;
    }
  }
}

.widget-layouts {
  display: flex;
  justify-content: center;
  gap: 32px;
  flex-wrap: wrap;
  overflow: auto;
  padding-bottom: 10px;
}