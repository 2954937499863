@import './variables.scss';

.color-gray-gray-8 {
  color: $grayGray8;
}

.color-gray-gray-9 {
  color: $grayGray9;
}

.color-gray-gray-10 {
  color: $grayGray10;
}

.color-secondary-colour-secondary-4 {
  color: $secondaryColourSecondary4;
}

.color-secondary-colour-secondary-5 {
  color: $secondaryColourSecondary5;
}

.color-secondary-colour-secondary-6 {
  color: $secondaryColourSecondary6;
}

.color-secondary-colour-secondary-7 {
  color: $secondaryColourSecondary7;
}

.color-secondary-colour-secondary-8 {
  color: $secondaryColourSecondary8;
}

.color-golden-purple-purple-5 {
  color: $goldenPurplePurple5;
}

.color-dust-red-red-5 {
  color: $dustRedRed5;
}

.color-polar-green-green-6 {
  color: $polarGreenGreen6;
}

.color-sunrise-yellow-yellow-7 {
  color: $sunriseYellowYellow7;
}

.border-color-gray-gray-5 {
  border-color: $grayGray5;
}

.bg-gray-gray-3 {
  background: $grayGray3;
}