.performance-card {
  display: flex;
  gap: 9px;
  align-items: center;
  &__icon {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    font-size: 20px;
    line-height: 1.4;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.0015em;
    text-align: center;
  }
  &__data {
    width: calc(100% - 49px);
  }
  &__values {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
}