@import "~normalize.css/normalize.css";
@import "sass/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import "sass/generic.scss";
@import "sass/margins.scss";
@import "sass/forms.scss";
@import "sass/colors.scss";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

html {
  background: $layoutBg;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.75;
  color: $grayGray9;
}

.container {
  max-width: 1440px;
  padding-left: 56px;
  padding-right: 56px;
  margin-left: auto;
  margin-right: auto;
}

.responsive-img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  margin: 0;
}

a {
  color: $goldenPurplePurple5;
  transition: $tr;
  &:hover {
    color: $goldenPurplePurple7;
  }
}

.Toastify__toast-container {
  min-height: auto;
  width: auto;

  .Toastify__toast {
    background: transparent;
    padding: 0;
    margin: 0;
    box-shadow: none;

    .Toastify__toast-body {
      box-shadow: none;
      padding: 0;
      border-radius: 0;
    }
  }
}

.ReactCollapse--collapse {
  transition: all ease $trans3;
}

@media only screen and (max-width: $mobileWidth) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}