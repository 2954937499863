.installation-status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, calc(-50% - 84px));
  &__icon {
    display: block;
    margin: 0 auto;
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
  &__inner {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}