@import '../../sass/variables.scss';

.cards-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    height: calc(100% - 82px);

    .cards-list {
        padding: 16px;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        
        &__item {
          width: calc(33.3333333% - 16px);
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          border: 1px solid transparent;
          transition: border-color ease 0.2s;
      
          &__selected {
            border-color: $goldenPurplePurple5;
          }
        }
    }
   
}