@import '../../sass/variables.scss';

.datepicker-wrapper {
  position: absolute !important;
  top: 100%;
  right: 4px;
  z-index: 1;
  &.fixed-position {
    position: fixed !important;
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
  }
  &__dates {
    font-size: 14px;
    line-height: 1.5;
  }
  .rdrStaticRange {
    border: 0;
  }
  .rdrStaticRangeLabel {
    padding: 8px 16px;
    transition: $tr;
    color: $secondaryColourSecondary7;
  }
  .rdrMonthAndYearPickers {
    display: none;
  }
  .rdrNextPrevButton {
    width: 16px;
    height: 16px;
    margin-left: 24px;
    margin-right: 24px;
    background: url(../../static/images/performance/akar-icons_chevron-left.svg) no-repeat center center;
    i {
      display: none;
    }
  }
  .rdrNextButton {
    transform: rotate(180deg);
  }
  .rdrMonthAndYearWrapper {
    height: 36px;
    padding-top: 10px;
    z-index: 1;
    position: relative;
  }
  .rdrMonths {
    margin-top: -24px;
  }
  .rdrMonthName {
    text-align: center;
    color: $secondaryColourSecondary7;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71;
    padding: 0 0 24px;
  }
  .rdrWeekDay {
    font-size: 14px;
    line-height: 1.71;
    font-weight: 500;
    color: $goldenPurplePurple5;
  }
  .rdrMonth {
    padding: 0 16px 16px;
    width: 270px;
  }
  .rdrDay {
    font-size: 14px;
    line-height: 1.71;
    height: 36px;
    &:not(.rdrDayPassive) {
      .rdrInRange,
      .rdrStartEdge,
      .rdrEndEdge,
      .rdrSelected {
        ~ .rdrDayNumber {
          span {
            color: $goldenPurplePurple5;
          }
        }
      }
    }
  }
  .rdrDays {
    margin-top: 8px;
  }
  .rdrDayToday {
    .rdrDayNumber {
      span {
        font-weight: 400;
        &:after {
          display: none;
        }
      }
    }
  }
  .rdrDayNumber {
    span {
      color: $secondaryColourSecondary7;
    }
  }
  .rdrDayPassive {
    span {
      color: $secondaryColourSecondary4;
    }
  }
  .rdrDayDisabled {
    opacity: 0.5;
  }
  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    background: $goldenPurplePurple1;
    color: $goldenPurplePurple5;
    height: 21px;
    top: 7px;
  }
  .rdrStartEdge {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background: $goldenPurplePurple1;
    color: $goldenPurplePurple5;
  }
  .rdrEndEdge {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background: $goldenPurplePurple1;
    color: $goldenPurplePurple5;
  }
  .rdrDayStartOfWeek {
    .rdrInRange {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
  .rdrDayEndOfWeek {
    .rdrInRange {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .rdrDayEndPreview,
  .rdrDayStartPreview,
  .rdrDayInPreview {
    display: none;
  }
  &_static {
    position: static !important;
    .rdrMonthAndYearPickers {
      display: flex;
    }
    .rdrMonths {
      margin-top: 16px;
    }
    .rdrNextPrevButton {
      margin: 0;
    }
    .rdrMonthAndYearPickers {
      select {
        font-size: 14px;
        line-height: 1.71;
        font-weight: 500;
        padding: 0 20px 0 0;
        background: url(../../static/images/widgets/akar-icons_chevron-down.svg) no-repeat right center;
      }
    }
    .rdrMonth {
      padding: 0;
      width: 100%;
    }
    .rdrDayNumber {
      &:after {
        display: none;
      }
    }
  }
}