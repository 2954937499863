@import '../sass/variables.scss';

.package-item {
  &__emails {
    border-top: 1px solid $grayGray4;
  }
  &__button {
    display: block;
    width: 100%;
  }
  &__included-label {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
  }
}

.services-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  &__item {
    width: calc(25%  - 6px);
    display: flex;
    gap: 8px;
    align-items: center;
    svg {
      display: block;
    }
  }
}