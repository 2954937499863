.base {
  display: flex;

  button {
    border-radius: 0;

    &:nth-child(odd) {
      border-right: 1px solid white;
      margin-right: -0.75px;
    }

    &:nth-child(even) {
      border-left: 1px solid white;
      margin-left: -0.75px;
    }

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }
}
