.m-auto {
  margin: auto;
}

.mt-2 {
  margin-top: 2px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-42 {
  margin-top: 42px;
}

.mt-44 {
  margin-top: 44px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-56 {
  margin-top: 56px;
}

.mt-58 {
  margin-top: 58px;
}
  
.mt-64 {
  margin-top: 64px;
}

.mt-72 {
  margin-top: 72px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-40 {
  margin-bottom: 40px;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 8px;
}

.pl-0 {
  padding-left: 0;
}

.pl-13 {
  padding-left: 13px;
}

.pl-26 {
  padding-left: 26px;
}

.pl-36 {
  padding-left: 36px;
}


.pl-48 {
  padding-left: 48px;
}

.pr-0 {
  padding-right: 0;
}

.pr-13 {
  padding-right: 13px;
}

.pr-24 {
  padding-right: 24px;
}

.pr-36 {
  padding-right: 36px;
}

.pr-48 {
  padding-right: 48px;
}

.pr-76 {
  padding-right: 76px !important;
}

.pr-198 {
  padding-right: 198px !important;
}

.pr-210 {
  padding-right: 210px !important;
}

.pr-235 {
  padding-right: 235px !important;
}

.pt-2 {
  padding-top: 2px;
}

.pt-4 {
  padding-top: 4px;
}

.pt-6 {
  padding-top: 6px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-12 {
  padding-top: 12px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-32 {
  padding-top: 32px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-44 {
  padding-top: 44px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-101 {
  padding-top: 101px;
}

.pt-102 {
  padding-top: 102px;
}

.pb-2 {
  padding-bottom: 2px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-22 {
  padding-bottom: 22px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-44 {
  padding-bottom: 44px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-101 {
  padding-bottom: 101px;
}

.pb-102 {
  padding-bottom: 102px;
}

@media only screen and (max-width: $mobileWidth) {
  .mt-0-mobile {
    margin-top: 0;
  }
  .mt-2-mobile {
    margin-top: 2px;
  }
  .mt-4-mobile {
    margin-top: 4px;
  }
  .mt-8-mobile {
    margin-top: 8px;
  }
  .mt-16-mobile {
    margin-top: 16px;
  }
  .mt-24-mobile {
    margin-top: 24px;
  }
  .mt-32-mobile {
    margin-top: 32px;
  }
  .pt-16-mobile {
    padding-top: 16px;
  }
  .pb-16-mobile {
    padding-bottom: 16px;
  }
  .pb-64-mobile {
    padding-bottom: 64px;
  }
}