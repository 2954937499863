@import "../../sass/variables.scss";

.ui-base-checkbox {
  position: relative;
  display: inline-block;
  &__input {
    position: absolute;
    width: 0;
    opacity: 0;
    &:checked {
      + .ui-base-checkbox {
        &__label {
          &:before {
            background-color: $goldenPurplePurple5;
            background-image: url(../../static/images/forms/Vector.svg);
          }
        }
      }
    }
  }
  &__label {
    position: relative;
    display: block;
    padding-left: 34px;
    cursor: pointer;
    color: $grayGray8;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 16px;
      height: 16px;
      border: 1px solid $grayGray5;
      border-radius: 2px;
      background: $grayGray1;
      transition: $tr;
      background-position: center center;
      background-repeat: no-repeat;
    }
    &:hover {
      &:before {
        border-color: $goldenPurplePurple5;
      }
    }
    &_spacing {
      &_8 {
        padding: 0 0 0 26px;
        &.ui-base-checkbox__label_position_left {
          padding-right: 26px;
          padding: 0 26px 0 0;
        }
      }
    }

    &_position {
      &_left {
        padding-left: 0;
        padding-right: 34px;
        &:before {
          left: auto;
          right: 0;
        }
      }
    }
    &_align {
      &_top {
        padding-left: 26px;
        &:before {
          top: 6px;
          transform: none;
        }
      }
    }
  }

  &[data-variant="pill"] {
    border: 1px solid $grayGray5;
    box-shadow: $cardsDropShadow;
    border-radius: 8px;

    label {
      padding: 8px 8px 8px 16px;
      min-height: 56px;
      display: flex;
      align-items: center;

      &:before {
        left: auto;
        right: 8px;
      }
    }
  }
}
