@import '../sass/variables.scss';

.breadcrumbs {
  display: flex;
  padding: 14px 3px 14px 0;
  align-items: center;
  gap: 8px;
}

.breadcrumbs-list {
  display: flex;
  gap: 32px;
  margin: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  &__item {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    &_block {
      display: block;
    }
    &:first-child {
      padding-right: 29px;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: -16px;
      top: 0;
      bottom: 0;
      width: 1px;
      background: $grayGray5;
    }
    &:first-child {
      &:after {
        display: none;
      }
    }
  }
}