.user-location-table {
  td {
    min-height: 64px;
    &:nth-child(3),
    &:nth-child(4) {
      text-align: right;
      > div {
        > div {
          justify-content: flex-end;
        }
      }
    }
  }
  th {
    &:nth-child(3),
    &:nth-child(4) {
      text-align: right;
      > div {
        > div {
          justify-content: flex-end;
        }
      }
    }
  }
  tbody {
    max-height: 257px;
    overflow: auto;
  }
  table {
    max-height: 297px;
    overflow: auto;
  }
  thead {
    position: sticky;
    top: 0;
  }
}