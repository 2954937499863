@import "../../sass/variables.scss";

.fixed-dropdown {
	position: fixed;
	background: $grayGray1;
	width: 287px;
	z-index: 2;
	box-shadow: $cardsDropShadow;

	&_widget {
		width: 228px;
		border: 1px solid $grayGray5;
		bottom: 100%;
		top: auto;
		margin-bottom: 4px;
		border-radius: 4px;
	}

	&_filter {
		margin-top: 10px;
	}

	&_campaign {
		width: 287px;
		border: 1px solid $grayGray5;
		border-radius: 4px;
	}

	&_position {
		&_bottom {
			top: 100%;
			margin-top: 4px;
			margin-bottom: auto;
			bottom: auto;
			left: 0;
			right: auto;
		}
	}

	&_w {
		&_340 {
			width: 340px;
		}
	}

	&__item {
		padding: 12px 14px;
		display: flex;
		width: 100%;
		text-align: left;
		border: 0;
		outline: 0;
		background: transparent;
		color: $secondaryColourSecondary7;
		cursor: pointer;
		transition: $tr;
		align-items: center;
		gap: 8px;
		
		&:hover {
			background: $grayGray3;
			color: $secondaryColourSecondary7;
		}

		&[disabled] {
			color: $grayGray5;
			cursor: default;

			&:hover {
				color: $grayGray5;
				background: transparent;
			}
		}

		&_selected {
			background: $grayGray4;
		}
	}
	
	&__item-nav {
		text-decoration: none;
	}

	&__item-icon {
		padding: 2px;
		display: block;
		color: $grayGray5;

		svg {
			display: block;
			width: 16px;
			height: 16px;
		}
	}
}