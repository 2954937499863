@import '../../sass/variables.scss';

.chips {
  padding: 0 4px;
  color: $dustRedRed6;
  border: 1px solid #f5222d80;
  background: #ffccc780;
  font-size: 10px;
  display: inline-flex;
  line-height: 1.6;
  letter-spacing: 0.05em;
  // display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  height: 16px;
  text-transform: uppercase;
  transition: $tr;
  justify-content: center;
  &_green {
    color: $polarGreenGreen6;
    border-color: #52c41a80;
    background: #d9f7be80;
    svg {
      transform: rotate(180deg);
      transition: $tr;
    }
  }
  &_blue {
    color: $geekBlueGeekBlue5;
    border-color: $geekBlueGeekBlue3;
    background: $geekBlueGeekBlue2;
  }
  &_yellow {
    border-color: $sunriseYellowYellow7;
    background: $sunriseYellowYellow1;
    color: $sunriseYellowYellow7;
  }
  svg {
    display: block;
  }
  &_size {
    &_large {
      padding: 2px 8px;
    }
    &_long {
      padding: 0 23px;
    }
  }
  &_gold {
    color: $calendulaGoldGold7;
    border-color: $calendulaGoldGold5;
    background: $calendulaGoldGold2;
  }
  &_minw {
    &_54 {
      min-width: 54px;
    }
    &_80 {
      min-width: 80px;
    }
  }
  &_magenta {
    color: $magentaMagenta5;
    border-color: $magentaMagenta3;
    background: $magentaMagenta2;
  }
  &_volcano {
    color: $volcanoVolcano5;
    border-color: $volcanoVolcano3;
    background: $volcanoVolcano2;
  }
}