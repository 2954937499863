.base {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;

  .illustration {
    padding: 30px 20px;
  }
}
